import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueShortKey from 'vue-shortkey';
import VueTelInput from 'vue-tel-input';
import VueSocketIO from 'vue-socket.io';
import { VLazyImagePlugin } from 'v-lazy-image';
import VTooltip from 'v-tooltip';
import { CURRENT_SERVER } from '@/api/constants/domains';
import PortalVue from 'portal-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.use(PortalVue);

Vue.use(new VueSocketIO({
  debug: false,
  connection: CURRENT_SERVER,
}));

Vue.use(VueTelInput);

Vue.use(VTooltip);

Vue.use(VLazyImagePlugin);

Vue.config.productionTip = false;

Vue.use(VueShortKey);

Vue.use(VeeValidate, {
  i18n,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    const { meta = {} } = to;
    const { title = 'UPPERBUSINESS - Admin Panel', description = '', keywords = '' } = meta;
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute('content', description);
    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
  });
});

new Vue({
  router,
  store,
  i18n,
  VeeValidate,
  render: (h) => h(App),
}).$mount('#app');
