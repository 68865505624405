<template>
  <footer class="landing-page-footer">
    <div class="landing-page__container">
      <div class="landing-page-footer-row">
        <div class="landing-page-footer-row__info">
          <div class="landing-page-footer-row__info-logo">
            <img
              src="@/assets/images/logo.svg"
              alt="logo"
              @click="proceedTo()"
            >
          </div>
          <div class="landing-page-footer-row__info-text">
            {{ $t('landingPage.footerSection.description') }}
          </div>
        </div>
        <div class="landing-page-footer-row-right">
          <div class="landing-page-footer-row-right__contacts">
            <div
              v-for="contact in contacts"
              :key="contact.id"
              class="landing-page-footer-row-right__contacts-item"
            >
              <i :class="contact.icon"></i>
              <template v-if="contact.link">
                <a
                  :href="contact.link"
                >
                  {{ contact.text }}
                </a>
              </template>
              <template v-else>
                <span>
                  {{ $t(contact.text) }}
                </span>
              </template>
            </div>
          </div>
          <div class="landing-page-footer-row-right__social">
            <div
              v-for="social in socials"
              :key="social.id"
              class="landing-page-footer-row-right__social-item"
            >
              <a
                target="_blank"
                :href="social.link"
              >
                <i :class="social.icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import socials from '@/constants/landing/socials';
import contacts from '@/constants/landing/contacts';

export default {
  name: 'FooterTemplateSecond',
  data: () => ({
    socials,
    contacts,
  }),
  methods: {
    proceedTo() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/mixins";

  .landing-page-footer {
    padding: 20px 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @include mobile {
      padding-top: 40px;
      padding-bottom: 35px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include tablet-portrait {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
      }

      &__info {
        display: flex;
        width: 30%;
        flex-grow: 1;

        @include tablet-landscape {
          flex-wrap: wrap;
          width: 25%;
        }

        @include mobile {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          align-items: center;

        }

        &-text {
          padding-left: 15px;
          max-width: 240px;
          font-size: $font-size-landing-small;
          line-height: 1.5;

          @include tablet-landscape {
            padding-left: 0;
            margin-top: 18px;
          }

          @include tablet-portrait {
            text-align: center;
          }

          @include mobile {
            margin-top: 0;
          }
        }

        &-logo {
          padding-right: 15px;
          position: relative;

          @include mobile {
            padding-right: 0;
            margin-bottom: 10px;
          }

          img {
            min-width: 180px;
            cursor: pointer;
          }

          &:after {
            position: absolute;
            content: '';
            right: 0;
            height: 32px;
            width: 1px;
            background: $color-gallery;
            top: 0;

            @include tablet-landscape {
              display: none;
            }
          }

          @include mobile {

            &:after {
              display: none;
            }
          }
        }
      }

      &-right {
        display: flex;
        flex-grow: 2;
        justify-content: flex-end;

        @include tablet-landscape {
          flex-wrap: wrap;
          width: 75%;
        }

        @include mobile {
          width: 100%;
        }

        &__social {
          display: flex;
          margin: 0 10px;

          @include tablet-landscape {
            margin-top: 22px;
            margin-left: 0;
            margin-right: 0;
            justify-content: flex-end;
          }

          @include tablet-portrait {
            justify-content: center;
            width: 100%;
            order: 0;
          }

          @include mobile {
            margin-top: 17px;
          }

          &-item {
            margin: 0 7px;

            @include tablet-landscape {
              &:last-child {
                margin-right: 0;
              }
            }
          }

          i {
            font-size: $font-size-icon-large;
            color: $color-dodger-blue;
            transition: color .15s ease-in;

            &:before {
              margin: 0;
            }

            &:hover {
              color: $color-denim;
            }
          }
        }

        &__contacts {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-end;
          padding-right: 15px;

          @include tablet-landscape {
            padding-right: 0;
          }

          @include tablet-portrait {
            text-align: center;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 20px;
            order: 1;
          }

          @include mobile {
            justify-content: center;
            margin-top: 23px;
          }

          &-item {

            &  + .landing-page-footer-row-right__contacts-item {
              margin-left: 26px;
            }

            @include tablet-portrait {

              &  + .landing-page-footer-row-right__contacts-item {
                margin-left: 16px;

                @include mobile {
                  margin-bottom: 18px;
                }

                @include mobile-old {
                  margin-left: 10px;
                }
              }

              &:last-child {

                @include tablet-landscape {
                  max-width: 100%;
                  text-align: center;
                  margin: 0 auto;
                }

                @include mobile {
                  width: 100%;
                }
              }

              & {
                margin-bottom: 18px;

                @include mobile {
                  margin-bottom: 18px;
                }
              }
            }
          }

          i {
            color: $color-dodger-blue;
            margin-right: 6px;

            &.ub-icon-phone {
              font-size: $font-size-landing-md;
              margin-right: 0;

              @include mobile-old {
                position: relative;
                top: 1px;
              }
            }
            &.ub-icon-email {

              @include mobile-old {
                position: relative;
                top: 1px;
              }
            }
          }

          a {
            text-decoration: none;
            color: $color-black;
            transition: color .15s ease-in;

            &:hover {
              color: $color-denim;
            }
          }
        }
      }
    }
  }
</style>
