import { render, staticRenderFns } from "./footerTemplate.vue?vue&type=template&id=1b51acaf&scoped=true&"
import script from "./footerTemplate.vue?vue&type=script&lang=js&"
export * from "./footerTemplate.vue?vue&type=script&lang=js&"
import style0 from "./footerTemplate.vue?vue&type=style&index=0&id=1b51acaf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b51acaf",
  null
  
)

export default component.exports