<template>
  <aside class="personal-area-left-sidebar">
    <VuePerfectScrollbar
      class="personal-area-left-sidebar__scroll"
    >
      <div class="personal-area-left-sidebar__top">
        <div
          class="personal-area-left-sidebar__logo"
          @click="goToPage('ordersList')"
        >
          <img
            src="@/assets/images/logo.svg"
            alt="logo"
          >
        </div>
        <nav class="personal-area-left-sidebar-menu">
          <a
            v-for="(item) in sidebarMenu"
            :key="item.id"
            class="personal-area-left-sidebar-menu__item"
            :class="[
              {'personal-area-left-sidebar-menu__item_active': activeMenuItem === item.route
                || activeMenuItem === item.routeAlt},
              {'personal-area-left-sidebar-menu__item_height': item.route === 'settings'}
            ]"
            :href="`#${item.route}`"
            @click.prevent="goToPage(item.route)"
          >
            <div>
              <i
                :class="`ub-icon-${item.icon}`"
              >
              </i>
              <span>
                {{ item.name }}
              </span>
            </div>
          </a>
        </nav>
      </div>
      <div class="personal-area-left-sidebar__bottom">
        <div
          v-if="userRole === 'Admin'"
          class="account"
        >
          <div
            class="account__content"
            :class="{'account__content_pointer': userRole === 'Admin'}"
            @click="userRole !== 'Admin' ? proceedToRoute('profile') : false"
          >
            <div
              class="account__image"
              :class="{'account__image_bg': userInfo.userAvatarUrl}"
            >
              <i
                v-if="!userInfo.userAvatarUrl"
                class="ub-icon-user"
              >
              </i>
              <img
                v-else
                :src="userInfo.userAvatarUrl"
                alt="user photo"
              >
            </div>
            <div class="account__name">
              {{ fullName }}
            </div>
          </div>
        </div>
        <div class="logout">
          <div
            class="logout__content"
            @click="logOut()"
          >
            <i class="ub-icon-logout logout__icon"></i>
            <span class="logout__text">
              {{ $t('global.logout') }}
            </span>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
  </aside>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import countriesApi from '@/api/countries/countriesApi';

export default {
  name: 'LeftSidebar',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      activeMenuItem: '',
    };
  },
  computed: {
    createdCountriesList: {
      get() {
        return this.$store.getters.createdCountriesList;
      },
      set(data) {
        this.$store.dispatch('setCreatedCountriesList', data);
      },
    },
    defaultCountries: {
      get() {
        return this.$store.getters.defaultCountries;
      },
      set(data) {
        this.$store.dispatch('setDefaultCountriesList', data);
      },
    },
    sidebarMenu() {
      if (this.userRole === 'Admin') {
        return [
          {
            id: 1,
            name: this.$t('personalLeftSidebar.orders'),
            route: 'ordersList',
            routeAlt: 'order',
            icon: 'order-personal',
          },
        ];
      } return [
        {
          id: 1,
          name: this.$t('personalLeftSidebar.orders'),
          route: 'ordersList',
          routeAlt: 'order',
          icon: 'order-personal',
        },
        {
          id: 2,
          name: this.$t('personalLeftSidebar.profile'),
          route: 'profile',
          icon: 'user-personal',
        },
      ];
    },
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
    userRole() {
      return this.userInfo.role;
    },
    fullName() {
      const {
        role, firstName = '', lastName = '', partnerCompanyName = '',
      } = this.userInfo;
      if (role === 'Partner') {
        return partnerCompanyName;
      }
      return `${firstName || ''} ${lastName || ''}`;
    },
  },
  watch: {
    '$route.path': function () {
      this.activeMenuItem = this.$route.name;
    },
  },
  beforeMount() {
    this.activeMenuItem = this.$route.name;
    countriesApi.getDefaultCountries()
      .then((resp) => {
        this.defaultCountries = resp.data;
      }).catch((err) => {
        console.error(err);
      });
  },
  methods: {
    proceedToRoute(route) {
      this.$router.push({
        name: route,
      }).catch(() => {});
    },
    goToPage(page) {
      this.$router.push({
        name: page,
      });
      this.activeMenuItem = page;
    },
    logOut() {
      this.userInfo = {};
      localStorage.removeItem('token');
      localStorage.removeItem('rememberMe');
      this.$router.push({
        name: `landing-${this.$i18n.locale}`,
      });
    },
  },
};

</script>

<style scoped lang="scss">
@import "../sass/variables";

.personal-area-left-sidebar {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 240px;
  background: $color-white;
  height: 100vh;
  padding: 21px 0 0;
  justify-content: space-between;
  position: sticky;
  top: 0;

  &__scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  &__logo {
    margin-left: 25px;
    margin-bottom: 20px;
    cursor: pointer;
    max-width: 163px;
    width: 100%;
  }

  &-menu {
    display: flex;
    flex-direction: column;

    &__dropdown {
      padding:  12px 10px 10px 30px;

      &-item {
        font: $font-size-md $font-global;
        display: flex;
        align-items: center;
        height: 26px;

        &_active {
          font: $font-size-md $font-global-medium;
        }
      }
    }

    &__item {
      height: 46px;
      display: block;
      padding: 13px 25px 13px;
      position: relative;
      cursor: pointer;
      transition: background-color .15s ease-in;
      user-select: none;
      text-decoration: none;

      &:focus {

        span {
          color: $color-dodger-blue;
        }
      }

      & + .personal-area-left-sidebar-menu__item {
        margin-top: 5px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 0 $borderRadius $borderRadius 0;
        background: $color-dodger-blue;
        width: 4px;
        opacity: 0;
        transition: .15s ease-in;
      }

      i {
        margin-right: 10px;
        color: $color-silver-chalice;
        transition: color .15s ease-in;
      }

      span {
        color: $color-silver-chalice;
        text-decoration: none;
        transition: color .15s ease-in;
      }

      &_active {
        background: rgba($color-dodger-blue, .08);

        span, i {
          color: $color-dodger-blue;
        }

        span {
          font-family: $font-global-medium;
        }

        &:before {
          opacity: 1;
        }
      }

      &:hover {
        span, i {
          color: $color-dodger-blue;
        }
      }

      &_height {
        padding-bottom: 14px;
        min-height: 105px;
      }
    }
  }
}

.logout {
  color: $color-silver-chalice;
  min-height: 74px;
  max-height: 74px;
  border-top: 1px solid $color-alabaster;
  display: flex;
  align-items: center;
  margin-top: 25px;
  user-select: none;

  &__content {
    height: 46px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 0 25px;
    position: relative;
    cursor: pointer;
    transition: background-color .15s ease-in;

    i {
      margin-right: 10px;
      color: $color-silver-chalice;
      transition: color .15s ease-in;
    }

    span {
      color: $color-silver-chalice;
      text-decoration: none;
      transition: color .15s ease-in;
    }

    &:hover, &_active {

      span, i {
        color: $color-dodger-blue;
      }

      span {
        font-family: $font-global-medium;
      }

      &:before {
        opacity: 1;
      }
    }
  }

  &__icon {
    font-size: $font-size-h2;
    color: $color-silver-chalice;
    margin-right: 10px;
  }

  &__text {
    cursor: pointer;
  }
}

.account {
  margin-top: 15px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  transition: opacity .15s ease-in;
  user-select: none;

  &__content {
    display: flex;
    align-items: center;
    transition: opacity .15s ease-in;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }

    &_pointer {
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__name {
    font-family: $font-global-medium;
    text-transform: capitalize;
  }

  &__image {
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    background: $color-dodger-blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    &_bg {
      background: none;
    }

    img {
      border-radius: 50%;
    }

    i {
      color: $color-white;
      font-size: $font-size-huge;
    }
  }
}

</style>
