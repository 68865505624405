import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  login(user) {
    const instWithCred = axios.create({
      baseURL: domains.AUTHORIZATION,
    });
    return instWithCred.post('login', user);
  },
  createAccount(user) {
    const instWithCred = axios.create({
      baseURL: domains.AUTHORIZATION,
    });
    return instWithCred.post('signup', user);
  },
  simpleSignup(user) {
    const instWithCred = axios.create({
      baseURL: domains.AUTHORIZATION,
    });
    return instWithCred.post('simple-signup', user);
  },
  currentUserInfo() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    return instWithCred.get('me');
  },
  createPartner(partner) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    return instWithCred.post('create', partner);
  },
  getPartnersList({
    page = 1, perPage = 5, search, filter,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    const searchQuery = search ? `&search=${search}` : '';
    const sortQuery = '&sort={"createdAt": -1}';
    return instWithCred.get(`partners?page=${page}&per_page=${perPage}${filterQuery}${searchQuery}${sortQuery}`);
  },
  updatePartner(partner) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    return instWithCred.patch(`${partner._id}`, { ...partner, _id: undefined });
  },
  deleteUser(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    return instWithCred.delete(`${id}`);
  },
  getUserById(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    return instWithCred.get(`${id}`);
  },
  getClientsList({
    page = 1, perPage = 5, search,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    const searchQuery = search ? `&search=${search}` : '';
    const sortQuery = '&sort={"createdAt": -1}';
    return instWithCred.get(`clients?page=${page}&per_page=${perPage}${searchQuery}${sortQuery}`);
  },
  updateUser(user) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`users/${user._id}`, { ...user, _id: undefined });
  },
  resetPassword(user) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.AUTHORIZATION,
    });
    return instWithCred.post('email/reset-password', user);
  },
  getStaffsList({
    page = 1, perPage = 5, search, sort, filter,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    const searchQuery = search ? `&search=${search}` : '';
    const sortQuery = sort ? `&sort=${JSON.stringify(sort)}` : '&sort={"createdAt": -1}';
    return instWithCred.get(`staff?page=${page}&per_page=${perPage}${filterQuery}${searchQuery}${sortQuery}`);
  },
  getDepartmentList(department) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.USERS_API,
    });
    return instWithCred.get(`departments/${department}`);
  },
};
