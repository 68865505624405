<template>
  <div class="admin-modal signup-page">
    <div class="admin-modal-wrap">
      <div class="signup-page-panel">
        <div class="signup-page-panel__logo">
          <img
            src="@/assets/images/logo.svg"
            alt="logo"
          >
          <div
            class="signup-page-panel__close"
            @click="closeModal"
          >
            <i class="ub-icon-close-circle signup-page-panel__icon"></i>
          </div>
        </div>
        <div class="admin-modal__content">
          <template v-if="!showAuthFast">
            <div class="admin-form-field">
              <textInput
                :labelText="$t('requestModal.name')"
                :typeInput="'userName'"
                :onlyLabelClass="'text-field__label_landing'"
                :textCapitalize="true"
                :class="{'error': $validator.errors.has('userName')}"
                :errorStatus="$validator.errors.has('userName')"
                :autofocus="true"
                :value.sync="user.firstName"
                :customClass="'text-field_alt'"
                @changeInput="() => removeValidationError('userName')"
              >
              </textInput>
              <transition name="fade-el">
                <span
                  v-show="$validator.errors.has('userName')"
                  class="validation"
                >
                  {{ $validator.errors.first('userName') }}
                </span>
              </transition>
            </div>
            <div class="admin-form-field">
              <div class="admin-form-field__label">
                {{ $t('requestModal.phone') }}
              </div>
              <div class="text-field__input">
                <VueTelInput
                  v-model="user.phone"
                  name="phone"
                  :required="required"
                  :validCharactersOnly="validCharactersOnly"
                  :inputOptions="inputOptions"
                  :placeholder="placeholder"
                  :maxLen="maxLen"
                  :class="{'error': $validator.errors.has('phone')}"
                  :errorStatus="$validator.errors.has('phone')"
                />
                <transition name="fade-el">
                  <span
                    v-show="$validator.errors.has('phone')"
                    class="validation"
                  >
                    {{ $validator.errors.first('phone') }}
                  </span>
                </transition>
                <transition name="fade-el">
                  <i
                    v-if="$validator.errors.has('phone')"
                    class="ub-icon-info-valid
                    landing-page-modal-wrap-content__icon
                    landing-page-modal-wrap-content__icon_error"
                  >
                  </i>
                </transition>
              </div>
            </div>
            <div class="admin-form-field">
              <textInput
                :labelText="'E-mail'"
                :onlyLabelClass="'text-field__label_landing'"
                :typeInput="'email'"
                :class="{'error': $validator.errors.has('email')}"
                :errorStatus="$validator.errors.has('email')"
                :value.sync="user.email"
                :customClass="'text-field_alt'"
                @changeInput="() => removeValidationError('email')"
              >
              </textInput>
              <transition name="fade-el">
                <span
                  v-show="$validator.errors.has('email')"
                  class="validation"
                >
                  {{ $validator.errors.first('email') }}
                </span>
              </transition>
            </div>
            <div
              class="signup-page-panel__footer"
            >
              <transition name="fade-el">
                <span
                  v-if="validationError.status"
                  class="validation"
                >
                  {{ validationError.text }}
                </span>
              </transition>
              <div
                class="signup-page__text"
                @click="showAuthFastWindow"
              >
                {{ $t('loginPage.loginForm.profileValidationExists') }}
              </div>
              <button
                class="ub-btn ub-btn_primary signup-page__btn"
                @click="signupUser"
              >
                {{ $t('global.proceed') }}
              </button>
            </div>
          </template>
          <transition
            v-else
            name="fade-content"
            mode="out-in"
          >
            <loginFast
              :service="service"
              :loader.sync="loader"
              @newOrder="newOrder"
              @showCreateFastWindow="showAuthFastWindow"
            />
          </transition>
        </div>
      </div>
    </div>
    <div
      class="admin-modal__overlay"
      @click="closeModal"
    >
    </div>
    <loader v-if="loader" />
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import loginFast from '@/components/modals/loginFast';
import userApi from '@/api/user/userApi';
import validationErrorMessage from '@/locales/validationErrorMessage';
import loader from '@/components/loader';
import { VueTelInput } from 'vue-tel-input';
import ordersApi from '@/api/orders/ordersApi';
import { orderStatus } from '@/constants/orderStatus';

const generator = require('generate-password');

export default {
  name: 'SignupFast',
  components: {
    textInput,
    loader,
    loginFast,
    VueTelInput,
  },
  props: {
    service: {
      type: Object,
      default: () => {},
    },
    showAuth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAuthFast: false,
      required: true,
      placeholder: '',
      maxLen: 17,
      validCharactersOnly: true,
      loader: false,
      user: {
        firstName: '',
        email: '',
        password: generator.generate({
          length: 10,
          numbers: true,
        }),
        phone: '',
        role: 'Client',
      },
      validationError: {
        text: '',
        status: false,
      },
      validator: {},
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    inputOptions() {
      return {
        required: true,
        mode: 'national',
        enabledFlags: false,
      };
    },
  },
  beforeMount() {
    const token = localStorage.getItem('token');
    this.showAuthFast = !!token || this.showAuth;
    const dict = {
      en: {
        custom: {
          email: {
            required: validationErrorMessage.en.inputRequired,
          },
          phone: {
            required: validationErrorMessage.en.inputPhone,
          },
          userName: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          email: {
            required: validationErrorMessage.ru.inputRequired,
          },
          phone: {
            required: validationErrorMessage.ru.inputPhone,
          },
          userName: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'email', rules: { required: true } });
    this.$validator.attach({ name: 'phone', rules: { required: true } });
    this.$validator.attach({ name: 'userName', rules: { required: true } });
  },
  methods: {
    showAuthFastWindow() {
      this.showAuthFast = !this.showAuthFast;
    },
    closeModal() {
      this.$emit('closeSingUpModal');
    },
    removeValidationError(error) {
      if (this.$validator.errors && this.$validator.errors.items) {
        const fieldError = this.$validator.errors.items.find((item) => item.field === error);
        if (fieldError) {
          this.$validator.errors.removeById(fieldError.id);
        }
      }
    },
    signupUser() {
      this.$validator.validateAll({
        email: this.user.email,
        phone: this.user.phone.length === this.maxLen || this.user.phone.length >= 11 ? this.user.phone : '',
        userName: this.user.firstName,
      }).then((result) => {
        if (result) {
          this.loader = true;
          userApi.simpleSignup(this.user).then((resp) => {
            const { token } = resp.data;
            this.$store.dispatch('setUserInfo', resp.data);
            const idUser = resp.data._id;
            localStorage.setItem('token', JSON.stringify(token));
            if (this.service) {
              this.newOrder(idUser);
            } else {
              this.loader = false;
              this.$emit('closeSingUpModal');
              this.$router.push({
                name: 'orders',
              });
            }
          }).catch((err) => {
            console.error(err);
            this.loader = false;
            const { message } = err.response.data;
            this.validationError = {
              status: true,
              text: err.response && (message.includes('Mail not sent'))
                ? this.$t('loginPage.loginForm.signupValidation')
                : this.$t('loginPage.loginForm.signupValidationExists'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          });
        }
      });
    },
    newOrder(id) {
      const {
        employeesAmount,
        registrationPeriod,
        director,
        country,
      } = this.service;
      const steps = {};
      Object.keys(this.service.steps).forEach((key) => {
        steps[key] = [];
        this.service.steps[key].forEach((step) => {
          if (step.isVisible) {
            steps[key].push({
              ...step,
            });
          }
        });
      });
      const newOrder = {
        ...this.service,
        employeesAmount: +employeesAmount,
        registrationPeriod: +registrationPeriod,
        director: director.code,
        client: id,
        country: country._id,
        directorCompany: this.service.directorCompany.map((el) => el.code),
        activityAreas: this.service.activityAreas.map((item) => item.code),
        status: orderStatus.NEW_ORDER,
        steps,
      };
      this.loader = true;
      ordersApi.addOrder(newOrder)
        .then((resp) => {
          this.loader = false;
          const { _id } = resp.data;
          this.$router.push({
            name: 'order',
            params: {
              openPayPal: true,
            },
            query: {
              id: _id,
            },
          });
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
  @import "src/sass/variables";
  @import "src/sass/mixins";

  .landing-page-modal-wrap {
    width: 396px;

    @include mobile-middle {
      width: 320px;
    }

    @include mobile-small {
      width: 280px;
    }

    &-content__icon {
      position: absolute;
      right: 12px;
      top: 64%;
      color: $color-silver-chalice;
      transition: color .15s ease-in;
      cursor: pointer;
      transform: translateY(-50%);

      &_error {
        pointer-events: none;
        color: $color-cardinal !important;
      }
    }
  }

  .admin-modal {
    &-wrap {
      border-radius: $borderRadius;
    }
  }

  .signup-page {

    &__text {
      font: $font-size-ml $font-landing-medium;
      color: $color-dodger-blue;
      transition: color .15s ease-in;
      cursor: pointer;

      &:hover {
        color: $color-denim;
      }
    }

    &__btn {
      width: 150px;
      height: 53px;
      font-family: $font-landing-medium;
      border-radius: $borderRadiusLarger;
    }

    &-panel {
      width: 416px;
      height: 427px;
      background: $color-white;
      border: 1px solid $color-gallery;
      border-radius: $borderRadius;
      padding: 40px 28px 24px;

      @include mobile-middle {
        width: 320px;
        padding: 40px 16px 16px;
      }

      @include mobile-small {
        width: 280px;
      }

      &__close {
        position: absolute;
        right: 18px;
        top: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity .15s ease-in;

        &:hover {
          opacity: .8;
        }
      }

      &__icon {
        color: $color-dodger-blue;
        background: rgba($color-dodger-blue, .08);
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &:before {
          font-size: $font-size-icon-extra-md;
        }
      }

      &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
      }

      &__error {
        margin-top: 20px;
        font: $font-size-h3 $font-global;
        color: $color-cardinal;
        position: absolute;
      }

      &__footer {
        position: relative;
        margin-top: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_signup {
          margin-top: 31px;
        }

        .validation {
          top: -25px;
          bottom: auto;
          max-width: 100%;
        }

      }

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &-text {
          font: $font-size-md $font-global-medium;
        }
      }
    }

    & .admin-form-field {

      &__label {
        font-family: $font-landing-medium;
      }
    }
  }
</style>
