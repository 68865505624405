<template>
  <aside
    class="admin-left-sidebar"
    :class="{'admin-left-sidebar_active': showFullSidebar}"
  >
    <loader v-if="loader" />
    <VuePerfectScrollbar
      class="admin-left-sidebar__scroll"
    >
      <div class="admin-left-sidebar__top">
        <div
          class="admin-left-sidebar__logo"
          :class="{'admin-left-sidebar__logo_full': showFullSidebar}"
          @click="goToRoute"
        >
          <img
            :key="showFullSidebar"
            class="admin-left-sidebar__image"
            :class="{'admin-left-sidebar__image_mini': !showFullSidebar}"
            :src="logoUrl"
            alt="logo"
          >
        </div>
        <nav class="admin-left-sidebar-menu">
          <template
            v-for="(item, index) in sidebarMenu"
          >
            <a
              v-if="showItem(item.role, item.department)"
              :key="item.id"
              class="admin-left-sidebar-menu__item"
              :class="[
                {'admin-left-sidebar-menu__item_active': activeMenuItem === item.route
                  || activeMenuItem === item.routeActive},
                {'admin-left-sidebar-menu__item_height': item.sub && showFullSidebar}
              ]"
              :href="`#${item.route}`"
              @click.stop.prevent="goToPage(item.route, $event, item.sub)"
              @mouseenter="showMenuPopup($event, index)"
              @mouseleave="showMenuPopup($event, null)"
            >
              <div class="admin-left-sidebar-menu__content">
                <i
                  :class="`ub-icon-${item.icon}`"
                >
                </i>
                <transition
                  name="fade-menu-item"
                  mode="out-in"
                >
                  <div
                    v-if="curItemMenu === index && !showFullSidebar"
                    class="admin-left-sidebar-popup"
                    :class="{'admin-left-sidebar-popup_no-hover': item.sub}"
                    :style="popupPosition"
                  >
                    <span
                      v-if="!item.sub"
                      class="admin-left-sidebar-popup__title"
                    >
                      {{ item.name }}
                    </span>
                    <div
                      v-else
                      class="admin-left-sidebar-popup__list"
                    >
                      <span
                        class="admin-left-sidebar-popup__title admin-left-sidebar-popup__title_disabled"
                        @click.stop
                      >
                        {{ item.name }}
                      </span>
                      <div
                        class="admin-left-sidebar-menu__dropdown admin-left-sidebar-menu__dropdown_alt"
                      >
                        <transition-group
                          name="fade-el"
                          mode="out-in"
                          tag="div"
                        >
                          <div
                            v-for="el in item.sub"
                            :key="el.id"
                            class="admin-left-sidebar-menu__dropdown-item"
                            :class="{'admin-left-sidebar-menu__dropdown-item_active': el.route === activeSubMenuItem
                              || el.routeSub === activeSubMenuItem}"
                            @click.stop.prevent="proceedToRoute(el.route)"
                          >
                            {{ el.name }}
                          </div>
                        </transition-group>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition
                  name="fade-content"
                  mode="out-in"
                  tag="div"
                >
                  <span
                    v-if="showFullSidebar"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </transition>
              </div>
              <div
                v-if="item.sub && isVisibleDropDown && showFullSidebar"
                class="admin-left-sidebar-menu__dropdown"
              >
                <transition-group name="fade-el">
                  <div
                    v-for="el in item.sub"
                    :key="el.id"
                    class="admin-left-sidebar-menu__dropdown-item"
                    :class="{'admin-left-sidebar-menu__dropdown-item_active': el.route === activeSubMenuItem
                      || el.routeSub === activeSubMenuItem}"
                    @click.stop.prevent="proceedToRoute(el.route)"
                  >
                    {{ el.name }}
                  </div>
                </transition-group>
              </div>
            </a>
          </template>
        </nav>
      </div>
      <div class="admin-left-sidebar__bottom">
        <div class="account">
          <div class="account__content">
            <div class="account__image">
              <i
                v-if="!userInfo.userAvatarUrl"
                class="ub-icon-user"
              >
              </i>
              <img
                v-else
                :src="userInfo.userAvatarUrl"
                alt="user photo"
              >
            </div>
            <transition
              name="fade-box"
              mode="out-in"
            >
              <div
                v-if="showFullSidebar"
                class="account__name"
              >
                {{ fullName }}
              </div>
            </transition>
          </div>
        </div>
        <div class="logout">
          <div
            class="logout__content"
            @click="logOut()"
          >
            <i class="ub-icon-logout logout__icon"></i>
            <transition
              name="fade-box"
              mode="out-in"
            >
              <div
                v-if="showFullSidebar"
                class="logout__text"
              >
                {{ $t('global.logout') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <div
      class="admin-left-sidebar-toggle"
      :class="{'admin-left-sidebar-toggle_active': showFullSidebar}"
      @click="toggleSidebar"
    >
      <i class="ub-icon-arrow-paginate"></i>
    </div>
  </aside>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import countriesApi from '@/api/countries/countriesApi';
import loader from '@/components/loader';
import {
  DEPARTMENT_HEAD, OWNER, CEO, MANAGER,
} from '@/constants/roleType';
import {
  UB_CONTACT, UB_CLIENT, UB_CONTENT, UB_BUSINESS_DEVELOPMENT,
} from '@/constants/listStaffDepartments';

export default {
  name: 'LeftSidebar',
  components: {
    VuePerfectScrollbar,
    loader,
  },
  data() {
    return {
      curItemMenu: null,
      popupPosition: {},
      showFullSidebar: true,
      activeMenuItem: '',
      activeSubMenuItem: '',
      loader: false,
      isVisibleDropDown: false,
    };
  },
  computed: {
    logoUrl() {
      if (this.showFullSidebar) {
        // eslint-disable-next-line global-require
        return require('@/assets/images/logo.svg');
      }
      // eslint-disable-next-line global-require
      return require('@/assets/images/logo-mini.svg');
    },
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    createdCountriesList: {
      get() {
        return this.$store.getters.createdCountriesList;
      },
      set(data) {
        this.$store.dispatch('setCreatedCountriesList', data);
      },
    },
    defaultCountries: {
      get() {
        return this.$store.getters.defaultCountries;
      },
      set(data) {
        this.$store.dispatch('setDefaultCountriesList', data);
      },
    },
    sidebarMenu() {
      return [
        {
          id: 1,
          name: this.$t('adminLeftSidebar.country'),
          route: 'country',
          icon: 'country',
          role: [DEPARTMENT_HEAD, MANAGER],
          department: [UB_CONTENT],
        },
        {
          id: 2,
          name: this.$t('adminLeftSidebar.partners'),
          route: 'partners',
          icon: 'partners',
          routeActive: 'partnerForAdmin',
          role: [DEPARTMENT_HEAD, MANAGER],
          department: [UB_BUSINESS_DEVELOPMENT],
        },
        {
          id: 3,
          name: this.$t('adminLeftSidebar.services'),
          route: 'services',
          icon: 'services',
          role: [MANAGER],
          department: [UB_CONTENT],
        },
        {
          id: 4,
          name: this.$t('adminLeftSidebar.staff'),
          route: 'staff',
          icon: 'staff',
          role: [DEPARTMENT_HEAD],
          department: [UB_CONTACT, UB_CLIENT, UB_BUSINESS_DEVELOPMENT],
        },
        {
          id: 5,
          name: this.$t('adminLeftSidebar.clients'),
          route: 'clients',
          routeActive: 'client',
          icon: 'clients',
          role: [DEPARTMENT_HEAD, MANAGER],
          department: [UB_CONTACT, UB_CLIENT],
        },
        {
          id: 6,
          name: this.$t('adminLeftSidebar.reports'),
          route: 'reports',
          icon: 'reports',
          role: [DEPARTMENT_HEAD],
          department: [UB_CONTACT],
        },
        {
          id: 7,
          name: this.$t('adminLeftSidebar.historyPayments'),
          route: 'payments',
          icon: 'bank-card',
          role: [],
          department: [],
        },
        {
          id: 8,
          name: this.$t('personalLeftSidebar.orders'),
          route: 'adminOrderList',
          routeActive: 'adminOrder',
          icon: 'order-personal',
          role: [DEPARTMENT_HEAD, MANAGER],
          department: [UB_CONTACT, UB_CLIENT, UB_BUSINESS_DEVELOPMENT],
        },
        {
          id: 9,
          name: this.$t('adminLeftSidebar.settings'),
          route: 'settings',
          icon: 'settings',
          role: [MANAGER],
          department: [UB_CONTENT],
          sub: [
            {
              id: 1,
              name: this.$t('adminLeftSidebar.typeServices'),
              route: 'typeServices',
              routeSub: 'typServices',
            },
            {
              id: 2,
              name: this.$t('adminLeftSidebar.others'),
              route: 'others',
            },
          ],
        },
      ];
    },
    userRole() {
      return this.userInfo.role;
    },
    userDepartment() {
      return this.userInfo.department;
    },
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
    fullName() {
      return `${this.userInfo.firstName || ''} ${this.userInfo.lastName || ''}`;
    },
  },
  watch: {
    '$route.name': function (newVal) {
      const showFullSidebarInfo = localStorage.getItem('showFullSidebar');
      this.showFullSidebar = showFullSidebarInfo !== 'hide';
      if (newVal === 'adminOrder') {
        this.goToPage(newVal);
      }
    },
  },
  beforeMount() {
    const showFullSidebarInfo = localStorage.getItem('showFullSidebar');
    this.showFullSidebar = showFullSidebarInfo !== 'hide';
    if (this.allCountriesList.length === 0) {
      countriesApi.getCreatedCountries().then((resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          arr.push(
            {
              ...item,
              image: `country/${item.code}.svg`,
            },
          );
        });
        this.allCountriesList = arr;
        return arr;
      }).catch((err) => {
        console.error(err);
      });
    }
    if (this.$route.name === 'settings') {
      this.isVisibleDropDown = true;
    }
    this.loader = true;
    this.activeMenuItem = this.$route.name;
    countriesApi.getDefaultCountries().then((resp) => {
      this.loader = false;
      this.defaultCountries = resp.data;
    }).catch((err) => {
      this.loader = false;
      console.error(err);
    });
  },
  methods: {
    goToRoute() {
      const isDepartment = this.userDepartment === UB_BUSINESS_DEVELOPMENT
          || this.userDepartment === UB_CLIENT || this.userDepartment === UB_CONTACT;
      this.$router.push({
        name: isDepartment ? 'adminOrderList' : 'country',
      });
      this.activeMenuItem = isDepartment ? 'adminOrderList' : 'country';
    },
    toggleSidebar() {
      this.showFullSidebar = !this.showFullSidebar;
      if (!this.showFullSidebar) {
        localStorage.setItem('showFullSidebar', 'hide');
      } else {
        localStorage.removeItem('showFullSidebar');
      }
      this.curItemMenu = null;
    },
    showItem(role, department) {
      if (this.userRole !== OWNER && this.userRole !== CEO) {
        if (department.includes(this.userDepartment)
            && role.includes(this.userRole)) {
          return true;
        }
        return false;
      }
      return true;
    },
    proceedToRoute(route) {
      this.activeSubMenuItem = route;
      this.$router.push({
        name: route,
      });
    },
    showMenuPopup(e, index) {
      const position = e.target.getBoundingClientRect();
      if (position) {
        this.popupPosition = {
          top: `${position.top}px`,
          right: 'auto',
        };
      } else {
        this.popupPosition = {};
      }
      this.curItemMenu = index;
    },
    goToPage(page, _, sub = []) {
      this.isVisibleDropDown = !!sub.length;
      if (page !== this.$route.name) {
        this.$router.push({
          name: page,
        });
      }
      if (!this.showFullSidebar && sub.length) {
        const { route } = sub[0];
        this.$router.push({
          name: route,
        });
        this.activeSubMenuItem = route;
      }
      this.activeMenuItem = page;
      this.curItemMenu = null;
    },
    logOut() {
      this.userInfo = {};
      localStorage.removeItem('token');
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('showFullSidebar');
      this.goToPage('loginPage');
    },
  },
};

</script>

<style scoped lang="scss">
  @import "../sass/variables";

  .admin-left-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 66px;
    max-width: 66px;
    background: $color-white;
    padding: 21px 0 0;
    justify-content: space-between;
    position: sticky;
    top: 0;
    transition: all .15s ease-in;
    z-index: 10;
    height: 98vh;

    &_active {
      min-width: 240px;
      max-width: 240px;

      .logout {

        &__content {
          padding: 0 25px;
        }
      }

      .account {
        padding: 0 25px;
      }
    }

    &__scroll {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 98vh;
    }

    &__bottom {
      position: relative;
    }

    &-toggle {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: -15px;
      bottom: 59px;
      min-width: 28px;
      max-width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 1px solid $color-gallery;
      background: $color-white;
      transition: background-color .15s ease-in;
      cursor: pointer;

      i {
        color: $color-silver-chalice;
        font-size: $font-size-small;
        transition: color .15s ease-in;
        position: relative;
      }

      &:hover {
        background: $color-hawkes-blue;

        i {
          color: $color-dodger-blue;
        }
      }

      &_active {
        i {
          transform: rotate(180deg);
          top: -1px;
          left: -1px;
        }
      }
    }

    &__image {
      max-width: 163px;
      position: relative;

      &_mini {
        max-width: 26px;
      }
    }

    &__logo {
      margin-bottom: 20px;
      padding: 0 20px;
      cursor: pointer;
      width: 100%;
      max-height: 30px;
      min-height: 30px;

      &_full {
        img {
          left: 2px;
        }
      }
    }

    &-popup {
      position: fixed;
      width: 195px;
      background: $color-white;
      min-height: 46px;
      padding: 12px 0;
      left: 70px;
      top: 72px;
      box-shadow: 0 0 8px rgba($color-black, .04);
      border-radius: $borderRadius;
      display: flex;
      align-items: center;
      z-index: 10;

      &:before {
        content: '';
        position: absolute;
        left: -4px;
        width: 4px;
        height: 100%;
        background: transparent;
      }

      &__list {
        width: 100%;
      }

      &__title {
        top: 0;
        color: $color-silver-chalice !important;
        padding: 0 16px;

        &_disabled {
          cursor: default;
          color: $color-silver-chalice;
        }
      }

      &:hover {
        .admin-left-sidebar-popup{
          &__title {
            color: $color-dodger-blue !important;
          }
        }
      }

      &_no-hover {
        &:hover {
          .admin-left-sidebar-popup{
            &__title {
              color: $color-silver-chalice !important;
            }
          }
        }
      }
    }

    &-menu {
      display: flex;
      flex-direction: column;

      &__dropdown {
        padding:  12px 10px 10px 30px;

        &-item {
          font: $font-size-md $font-global;
          display: flex;
          align-items: center;
          height: 26px;

          &_active {
            font: $font-size-md $font-global-medium;
          }
        }

        &_alt {
          padding: 0;
          margin-top: 16px;
          border-top: 1px solid $color-gallery;

          .admin-left-sidebar-menu {
            &__dropdown {
              &-item {
                padding: 0 16px;
                height: 34px;
                color: $color-silver-chalice;
                font-family: $font-global;

                &:hover {
                  background: $color-alabaster;
                }
              }
            }
          }
        }
      }

      &__content {
        display: flex;
      }

      &__item {
        height: 46px;
        display: block;
        padding: 13px 20px 13px;
        position: relative;
        cursor: pointer;
        transition: background-color .15s ease-in;
        user-select: none;
        text-decoration: none;

        &:focus {

          span {
            color: $color-dodger-blue;
          }
        }

        & + .admin-left-sidebar-menu__item {
          margin-top: 7px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-radius: 0 $borderRadius $borderRadius 0;
          background: $color-dodger-blue;
          width: 4px;
          opacity: 0;
          transition: .15s ease-in;
        }

        i {
          margin-right: 10px;
          color: $color-silver-chalice;
          transition: color .15s ease-in;
          font-size: $font-size-xlg;
        }

        span {
          color: $color-silver-chalice;
          text-decoration: none;
          transition: all .15s ease-in;
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          position: relative;
          top: 1px;
        }

        &_active {
          background: rgba($color-dodger-blue, .08);

          span, i {
            color: $color-dodger-blue;
          }

          span {
            font-family: $font-global-medium;
          }

          &:before {
            opacity: 1;
          }
        }

        &:hover {
          span, i {
            color: $color-dodger-blue;
          }
        }

        &_height {
          padding-bottom: 14px;
          min-height: 105px;
        }
      }
    }
  }

  .logout {
    color: $color-silver-chalice;
    min-height: 74px;
    max-height: 74px;
    border-top: 1px solid $color-alabaster;
    display: flex;
    align-items: center;
    margin-top: 25px;
    user-select: none;

    &__content {
      height: 46px;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0 17px;
      position: relative;
      cursor: pointer;
      transition: all .15s ease-in;

      i {
        margin-right: 10px;
        color: $color-silver-chalice;
        transition: color .15s ease-in;
      }

      span {
        color: $color-silver-chalice;
        text-decoration: none;
        transition: color .15s ease-in;
      }

      &:hover, &_active {

        span, i {
          color: $color-dodger-blue;
        }

        span {
          font-family: $font-global-medium;
        }

        &:before {
          opacity: 1;
        }
      }
    }

    &__icon {
      font-size: $font-size-h2;
      color: $color-silver-chalice;
      margin-right: 10px;
    }

    &__text {
      display: inline-block;
      cursor: pointer;
    }
  }

  .account {
    margin-top: 15px;
    padding: 0 17px;
    display: flex;
    align-items: center;
    transition: all .15s ease-in;
    user-select: none;

    &__content {
      display: flex;
      align-items: center;
      transition: opacity .15s ease-in;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }
    }

    &__name {
      font-family: $font-global-medium;
      text-transform: capitalize;
    }

    &__image {
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      background: $color-dodger-blue;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;

      img {
        border-radius: 50%;
      }

      i {
        color: $color-white;
        font-size: 22px;
      }
    }
  }

</style>
