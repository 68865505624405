<template>
  <div id="app">
    <portalTarget name="modalRefund" />
    <portalTarget name="modalSettings" />
    <portalTarget name="modalProfile" />
    <leftSidebar v-if="showSidebar" />
    <leftSidebarPersonalArea v-if="showSidebarClient" />
    <div
      class="ub-main"
      :class="[
        {'admin-main': showSidebar},
        {'landing-layout': checkLandingPage},
        {'ub-main_account': checkAccountPages}
      ]"
    >
      <landingHeader
        v-if="checkLandingPage"
        :secondary="showSecondaryHeader"
      />
      <main
        :class="[checkLandingPage ? 'landing-main' : '',
                 checkHeaderMargin ? 'landing-main_margin' : '']"
      >
        <router-view />
      </main>
      <footerTemplate v-if="checkLandingPage" />
      <footerTemplateSecond v-if="false" />
      <transition name="fade-el">
        <cookieNotification
          v-if="checkLandingPage && !cookieRules"
          :cookieRules.sync="cookieRules"
          @modalCookieInfo="modalCookieInfo"
        />
      </transition>
      <transition name="fade-el">
        <cookieModalInfo
          v-if="isVisibleCookieInfo"
          @modalCookieInfo="modalCookieInfo"
        />
      </transition>
    </div>
    <transition
      name="fade-content"
      appear
    >
      <div
        v-if="showWhatsappBtn"
        class="whatsapp-button"
      >
        <a
          href="https://wa.me/971521841181"
          target="_blank"
        >
          <img
            :src="require('@/assets/images/whatsapp-icon.svg')"
            alt="whatsapp-icon"
          >
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import leftSidebar from '@/components/leftSidebar';
import leftSidebarPersonalArea from '@/components/leftSidebarPersonalArea';
import footerTemplateSecond from '@/components/landing/footerTemplateSecond';
import cookieNotification from '@/components/modals/cookieNotification';
import footerTemplate from '@/components/landing/footerTemplate';
import landingHeader from '@/components/landing/landingHeader';
import cookieModalInfo from '@/components/landing/modals/cookieModalInfo';
import userApi from '@/api/user/userApi';
import isAdmin from '@/mixins/isAdmin';
import { find } from 'lodash';
import { PARTNER, CLIENT } from '@/constants/roleType';

export default {
  name: 'App',
  components: {
    leftSidebar,
    landingHeader,
    footerTemplate,
    footerTemplateSecond,
    cookieNotification,
    cookieModalInfo,
    leftSidebarPersonalArea,
  },
  mixins: [
    isAdmin,
  ],
  data() {
    return {
      cookieRules: false,
      isVisibleCookieInfo: false,
    };
  },
  computed: {
    showSidebar() {
      const token = localStorage.getItem('token');
      return this.$route.path.includes('admin') && token;
    },
    showWhatsappBtn() {
      return !this.$route.path.includes('admin')
          && !this.$route.path.includes('login')
          && !this.$route.path.includes('profile')
          && !this.$route.path.includes('orders')
          && !this.$route.path.includes('orders-list')
          && !this.$route.path.includes('order');
    },
    showSidebarClient() {
      const token = localStorage.getItem('token');
      return (!this.$route.path.includes('admin') && (this.$route.path.includes('personal-area')
          || this.$route.path.includes('profile')
          || this.$route.path.includes('orders-list')
          || this.$route.path.includes('order'))
      ) && token;
    },
    showSecondaryHeader() {
      const routerList = ['partner', 'about'];
      const page = find(routerList, (item) => `${item}-${this.$i18n.locale}` === this.$route.name);
      return page !== undefined;
    },
    checkLandingPage() {
      return (!this.$route.path.includes('admin')
          && this.$route.name !== 'loginPage' && !this.$route.path.includes('personal-area')
          && !this.$route.path.includes('profile') && !this.$route.path.includes('orders-list')
          && !this.$route.path.includes('order')
      )
          || this.$route.name === 'selection-service';
    },
    checkHeaderMargin() {
      const routerList = ['selectionService', 'service', 'compare', 'contacts', 'page404'];
      const page = find(routerList, (item) => `${item}-${this.$i18n.locale}` === this.$route.name
          || this.$route.name === 'page404');
      return page !== undefined;
    },
    checkAccountPages() {
      const routerList = ['profile', 'ordersList', 'order'];
      const page = find(routerList, (item) => item === this.$route.name);
      return page !== undefined;
    },
  },
  watch: {
    '$route.path': function () {
      this.handleScroll();
    },
  },
  mounted() {
    this.$nextTick(() => {
      const routerList = ['/login', '/profile', '/orders', '/orders-list', '/order'];
      const { location: { pathname } } = window;
      const page = find(routerList, (item) => item === pathname);
      if (!pathname.includes('admin') && page === undefined) {
        document.body.classList.add('landing');
      } else {
        document.body.classList.add('admin');
      }
    });
  },
  beforeMount() {
    const rules = JSON.parse(localStorage.getItem('cookie'));
    if (rules) {
      this.cookieRules = true;
    } else {
      this.cookieRules = false;
    }
    const { location: { pathname }, navigator } = window;
    const lang = pathname.split('/')[1];
    if (lang === 'en' || lang === 'ru') {
      this.$i18n.locale = lang;
    } else {
      const activeLanguage = localStorage.getItem('language');
      if (activeLanguage) {
        this.$i18n.locale = activeLanguage;
      } else {
        const language = navigator ? (navigator.language
            || navigator.systemLanguage
            || navigator.userLanguage) : 'ru';
        const systemLanguage = language ? language.substr(0, 2).toLowerCase() : 'ru';
        if (systemLanguage !== 'ru') {
          this.$i18n.locale = 'en';
        }
      }
      if (pathname === '/') {
        this.$router.push({
          name: `landing-${this.$i18n.locale}`,
        });
      }
    }
    // const rememberMe = localStorage.getItem('rememberMe');
    // if (rememberMe === 'false' || !rememberMe) {
    //   localStorage.removeItem('token');
    // }
    const token = localStorage.getItem('token');
    if (!token) {
      if (this.$route.path.includes('admin')) {
        if (!token) {
          if (this.$route.name !== 'loginPage') {
            this.$router.push({ name: 'loginPage' });
          }
        }
      }
    } else {
      userApi.currentUserInfo()
        .then((resp) => {
          this.$store.dispatch('setUserInfo', resp.data);
          const { role } = resp.data;
          if ((role === CLIENT || role === PARTNER) && this.$route.path.includes('admin')) {
            this.$router.push({
              name: 'page404',
              query: {},
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  methods: {
    handleScroll() {
      window.scrollTo(0, 0);
    },
    modalCookieInfo(data) {
      this.isVisibleCookieInfo = data;
    },
  },
};
</script>

<style lang="scss">
@import './sass/style';

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  src: url('./assets/fonts/roboto/Roboto.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  src: url('./assets/fonts/ubuntu/ubuntu.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu-medium';
  src: url('./assets/fonts/ubuntu/ubuntumedium.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-bold';
  src: url('./assets/fonts/ubuntu/ubuntubold.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Heavy';
  src: url('./assets/fonts/lato/Lato-Heavy.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Medium';
  src: url('./assets/fonts/lato/Lato-Medium.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('./assets/fonts/lato/Lato-Regular.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Semibold';
  src: url('./assets/fonts/lato/Lato-Semibold.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Thin';
  src: url('./assets/fonts/lato/Lato-Thin.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

</style>

<style lang="scss" scoped>
@import './sass/mixins';

.ub-main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.landing-layout {
  height: 100vh;
  justify-content: space-between;
}

.landing-main {
  flex-grow: 1;
  margin-top: -131px;

  @include desktop-max {
    margin-top: -108px;
  }

  &_margin {
    margin-top: 0;
  }
}
</style>
