const validationErrorMessage = {
  ru: {
    inputRequired: 'Поле обязательно для заполнения',
    inputRequiredAlt: 'Обязательное поле',
    inputEmail: 'Введите корректный email адрес',
    inputPhone: 'Введите корректный номер телефона',
  },
  en: {
    inputRequired: 'Required field',
    inputRequiredAlt: 'Required field',
    inputEmail: 'Email must be valid',
    inputPhone: 'Enter the correct phone number',
  },
};

export default validationErrorMessage;
