import * as types from '@/store/constant/mutationsTypes';

const state = {
  allCountriesList: [],
  defaultCountries: [],
  currentCountry: {},
};

const getters = {
  allCountriesList: (state) => state.allCountriesList,
  defaultCountries: (state) => state.defaultCountries,
  currentCountry: (state) => state.currentCountry,
};

const actions = {
  setAllCountriesList({ commit }, data) {
    commit(types.SET_ALL_COUNTRIES_LIST, data);
  },
  setDefaultCountriesList({ commit }, data) {
    commit(types.SET_DEFAULT_COUNTRIES_LIST, data);
  },
};

const mutations = {
  [types.SET_ALL_COUNTRIES_LIST](state, data) {
    state.allCountriesList = data;
  },
  [types.SET_DEFAULT_COUNTRIES_LIST](state, data) {
    state.defaultCountries = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
