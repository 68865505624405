<template>
  <div
    class="landing-cookies"
  >
    <div class="landing-cookies-info">
      <div class="landing-cookies__text">
        {{ $t('cookiesNotification.text') }}
        <span
          class="landing-cookies__link"
          @click="showModalCookieInfo"
        >
          {{ $t('cookiesNotification.link') }}
        </span>
      </div>
    </div>
    <div class="landing-cookies-btns">
      <button
        class="ub-btn ub-btn_border"
        @click="close"
      >
        <span>
          {{ $t('global.noThanks') }}
        </span>
      </button>
      <button
        class="ub-btn ub-btn_primary"
        @click="accept"
      >
        <span>
          {{ $t('global.ok') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieNotification',
  props: {
    cookieRules: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    showModalCookieInfo() {
      this.$emit('modalCookieInfo', true);
    },
    close() {
      this.$emit('update:cookieRules', true);
      localStorage.setItem('cookie', false);
    },
    accept() {
      this.$emit('update:cookieRules', true);
      localStorage.setItem('cookie', true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/mixins";

  .landing-cookies {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: rgba($color-black, .9);
    color: $color-white;
    min-height: 80px;
    padding: 15px 24px;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    user-select: none;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;

    @include tablet-portrait {
      flex-wrap: wrap;
    }

    &-info {
      display: flex;
      align-items: center;
      max-width: 100%;
      padding-right: 50px;

      @include tablet-portrait {
        width: 100%;
        padding-right: 0;
      }
  }

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-base;
      line-height: 1.5;

      @include tablet-portrait {
        text-align: center;
        width: 100%;
      }
  }

    &__link {
      text-decoration: none;
      position: relative;
      color: $color-white;
      transition: color .15s ease-in;
      cursor: pointer;

      @include mobile {
        white-space: nowrap;
      }

      &:hover {
        color: $color-silver-chalice;
      }

      &:after {
        background: $color-dodger-blue;
        position: absolute;
        content: '';
        top: 18px;
        width: 100%;
        height: 2px;
        left: 0;
    }
  }

    &-btns {
      display: flex;

      @include tablet-portrait {
        width: 100%;
        justify-content: center;
        margin-top: 15px;
      }

      button + button {
        margin-left: 12px;
      }
  }
}

</style>
