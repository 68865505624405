import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  getCreatedCountries() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.COUNTRY_API,
    });
    return instWithCred.get('countries/all');
  },
  getCreatedCountriesWithServices(queryParams) {
    const instWithCred = axios.create({
      baseURL: domains.COUNTRY_API,
    });
    return instWithCred.get(`countries/created/${queryParams ? `?${queryParams}` : ''}`);
  },
  getDefaultCountries() {
    const instWithCred = axios.create({
      baseURL: domains.COUNTRY_API,
    });
    return instWithCred.get('countries/');
  },
  deleteCountry(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.COUNTRY_API,
    });
    return instWithCred.delete(`countries/${id}`);
  },
  addCountry(country) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.COUNTRY_API,
    });
    return instWithCred.post('countries', country);
  },
  updateCountry(id, country) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.COUNTRY_API,
    });
    return instWithCred.patch(`countries/${id}`, country);
  },
};
