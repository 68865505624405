<template>
  <div class="landing-page-modal">
    <div class="landing-page-modal-wrap">
      <div class="landing-page-modal-wrap-header">
        <div class="landing-page-modal-wrap-header__title">
          {{ $t('global.requrestACall') }}
        </div>
        <div
          class="landing-page-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle landing-page-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="landing-page-modal-wrap-content">
        <transition
          name="fade-content"
          mode="out-in"
        >
          <div
            v-if="activeBlock === 1"
          >
            <div class="admin-form-field">
              <div class="admin-form-field__label admin-form-field__label_landing">
                {{ $t('requestCallModal.name') }}
              </div>
              <textInput
                :typeInput="'text'"
                :autofocus="true"
                :onlyInputClass="'text-field__input_landing'"
                :value.sync="user.name"
                :class="{'error': $validator.errors.has('name')}"
                :errorStatus="$validator.errors.has('name')"
              >
              </textInput>
              <transition name="fade-el">
                <span
                  v-show="$validator.errors.has('name')"
                  class="validation"
                >
                  {{ $validator.errors.first('name') }}
                </span>
              </transition>
            </div>
            <div class="admin-form-field">
              <div class="admin-form-field__label admin-form-field__label_landing">
                {{ $t('landingPage.contactsPage.phone') }}
              </div>
              <div class="text-field__input text-field__input_landing">
                <VueTelInput
                  v-model="user.phone"
                  name="phone"
                  :inputOptions="inputOptions"
                  :validCharactersOnly="validCharactersOnly"
                  :placeholder="placeholder"
                  :maxLen="maxLen"
                  :class="{'error': $validator.errors.has('phone')}"
                  :errorStatus="$validator.errors.has('phone')"
                />
                <transition name="fade-el">
                  <span
                    v-show="$validator.errors.has('phone')"
                    class="validation"
                  >
                    {{ $validator.errors.first('phone') }}
                  </span>
                </transition>
                <transition name="fade-el">
                  <i
                    v-if="$validator.errors.has('phone')"
                    class="ub-icon-info-valid
                  landing-page-modal-wrap-content__icon
                   landing-page-modal-wrap-content__icon_error"
                  ></i>
                </transition>
              </div>
            </div>
          </div>
        </transition>
        <transition
          name="fade-content"
          mode="out-in"
        >
          <div
            v-if="activeBlock === 2"
            class="successful-info"
          >
            <div class="successful-info__icon">
              <i class="ub-icon-check-mark-select"></i>
            </div>
            <div class="successful-info__text">
              <span>{{ $t('success.successText.textFirst') }}</span>
              <span>{{ $t('success.successText.textSecond') }}</span>
            </div>
          </div>
        </transition>
        <transition name="fade-el">
          <div
            v-if="validationError.status"
            class="landing-page-modal__error"
          >
            {{ validationError.text }}
          </div>
        </transition>
      </div>
      <div class="landing-page-modal-wrap-footer">
        <div class="landing-page-modal-wrap-footer__item">
          <button
            v-if="activeBlock === 1"
            class="landing-page__button landing-page__button_primary landing-page__button_no-border-radius"
            @click="sendForm"
          >
            {{ $t('global.send') }}
          </button>
          <button
            v-else-if="activeBlock === 2"
            class="landing-page__button landing-page__button_primary landing-page__button_no-border-radius"
            @click="closeModal"
          >
            {{ $t('global.ok') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="landing-page-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import validationErrorMessage from '@/locales/validationErrorMessage';
import textInput from '@/components/fields/textInput';
import formApi from '@/api/forms/formApi';
import { VueTelInput } from 'vue-tel-input';
import leadFields from '@/constants/amoCrm/leadFields';

export default {
  name: 'RequestCall',
  components: {
    textInput,
    VueTelInput,
  },
  data: () => ({
    activeBlock: 1,
    required: true,
    placeholder: '',
    validCharactersOnly: true,
    maxLen: 17,
    validationError: {
      text: '',
      status: false,
    },
    user: {
      name: '',
      phone: '',
    },
  }),
  computed: {
    inputOptions() {
      return {
        required: true,
        enabledFlags: false,
        mode: 'national',
      };
    },
  },
  beforeMount() {
    const dict = {
      en: {
        custom: {
          name: {
            required: validationErrorMessage.en.inputRequired,
          },
          phone: {
            required: validationErrorMessage.en.inputPhone,
          },
        },
      },
      ru: {
        custom: {
          name: {
            required: validationErrorMessage.ru.inputRequired,
          },
          phone: {
            required: validationErrorMessage.ru.inputPhone,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'name', rules: { required: true } });
    this.$validator.attach({ name: 'phone', rules: { required: true } });
  },
  methods: {
    closeModal() {
      this.$emit('closeRequestCallModal');
    },
    sendForm() {
      const { location: { href, origin } } = window;
      const utm = JSON.parse(sessionStorage.getItem('utm'));
      this.$validator.validateAll({
        name: this.user.name,
        phone: this.user.phone.length === this.maxLen || this.user.phone.length >= 11 ? this.user.phone : '',
      }).then((result) => {
        if (result) {
          const form = {
            name: this.user.name,
            phone: this.user.phone,
            lead_fields: {
              [`${leadFields}`]: origin,
            },
            url: origin,
            orderUrl: href,
            slug: 'bs-call',
            utm: utm || undefined,
          };
          formApi.requestCall(form).then(() => {
            this.activeBlock = 2;
            setTimeout(() => {
              this.$emit('closeRequestCallModal');
            }, 3000);
          }).catch((err) => {
            if (err.response && (err.response.data.statusCode === 500)) {
              this.validationError = {
                status: true,
                text: this.$t('requestModal.validationPhoneText'),
              };
              setTimeout(() => {
                this.validationError = {
                  status: false,
                  text: '',
                };
              }, 3000);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";
@import "src/sass/mixins";

.landing-page-modal-wrap {
  width: 396px;

  @include mobile-middle {
    width: 320px;
  }

  @include mobile-small {
    width: 280px;
  }

  &-content__icon {
    position: absolute;
    right: 12px;
    top: 64%;
    color: $color-silver-chalice;
    transition: color .15s ease-in;
    cursor: pointer;
    transform: translateY(-50%);

    &_error {
      pointer-events: none;
      color: $color-cardinal !important;
    }
  }
}

</style>
