var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landing-header",class:[
    {'landing-header_secondary': _vm.secondary},
    {'landing-header_mobile': _vm.toggleMenu}
  ]},[_c('div',{staticClass:"landing-page__container"},[_c('div',{staticClass:"landing-header__wrapper"},[_c('div',{staticClass:"landing-header__left",class:{'landing-header__left_login': _vm.firstName}},[_c('a',{staticClass:"landing-header__logo",attrs:{"href":"/","rel":"noopener noreferrer"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage('landing')}}},[(!_vm.mobileStatus)?[(!_vm.secondary)?_c('transition',{attrs:{"name":"fade-el","mode":"out-in","appear":""}},[_c('v-lazy-image',{attrs:{"src":require('@/assets/images/logo.svg'),"srcPlaceholder":require('@/assets/images/landing/phImage.svg'),"alt":"logo","title":"Upper Business"}})],1):_c('transition',{attrs:{"name":"fade-el","mode":"out-in","appear":""}},[_c('v-lazy-image',{attrs:{"src":require('@/assets/images/logo-landing.svg'),"srcPlaceholder":require('@/assets/images/landing/phImage.svg'),"alt":"logo","title":"Upper Business"}})],1)]:[((_vm.$route.name === ("partner-" + _vm.language) || _vm.$route.name === ("about-" + _vm.language))
                || (_vm.$route.name === ("landing-" + _vm.language) && _vm.toggleMenu)
                || (_vm.$route.name === ("contacts-" + _vm.language) && _vm.toggleMenu))?_c('transition',{attrs:{"name":"fade-el","mode":"out-in","appear":""}},[_c('v-lazy-image',{attrs:{"src":require('@/assets/images/logo-landing.svg'),"alt":"logo","title":"Upper Business","srcPlaceholder":require('@/assets/images/landing/phImage.svg')}})],1):_c('transition',{attrs:{"name":"fade-el","mode":"out-in","appear":""}},[_c('v-lazy-image',{attrs:{"src":require('@/assets/images/logo.svg'),"alt":"logo","title":"Upper Business","srcPlaceholder":require('@/assets/images/landing/phImage.svg')}})],1)]],2),_c('input',{staticClass:"burger",attrs:{"id":"burg","type":"checkbox"}}),_c('label',{staticClass:"btn-menu",class:{'btn-menu_active': _vm.toggleMenu},attrs:{"for":"burg"},on:{"click":_vm.toggleMenuMobile}},[_c('span',{staticClass:"item"}),_c('span',{staticClass:"item"}),_c('span',{staticClass:"item"})])]),_c('div',{staticClass:"landing-header__right"},[_c('ul',{staticClass:"landing-header-menu"},_vm._l((_vm.landingHeaderMenu),function(itemMenu){return _c('li',{key:itemMenu.id,staticClass:"landing-header-menu__item",class:[
              {'landing-header-menu__item_secondary': _vm.secondary},
              {'landing-header-menu__item_active': _vm.activeRoute === ((itemMenu.route) + "-" + _vm.language)},
              {'landing-header-menu__item_contacts': _vm.activeRoute === ("contacts-" + _vm.language)}
            ]},[_c('span',{on:{"click":function($event){return _vm.goToPage(itemMenu.route)}}},[_vm._v(" "+_vm._s(_vm.$t(itemMenu.text))+" ")])])}),0),_c('dropDown',{class:_vm.checkBgLine ? 'ub-dropdown_landing_color' : '',attrs:{"dropdownOptions":_vm.dropdownOptions,"customClass":'ub-dropdown_landing',"arrowAlt":true,"hideText":true,"hideArrow":true},on:{"changeDropdown":_vm.changeLanguage}}),_c('div',{staticClass:"auth",on:{"click":function($event){return _vm.toggleSingUpModal()}}},[_c('div',{staticClass:"auth__icon"},[(Object.keys(_vm.userInfo).length)?[(!_vm.userAvatarUrl)?_c('div',{staticClass:"auth__user"},[_c('i',{staticClass:"ub-icon-user"})]):(_vm.userAvatarUrl)?_c('img',{staticClass:"auth__avatar",attrs:{"src":_vm.userAvatarUrl}}):_vm._e()]:_c('i',{staticClass:"ub-icon-user-ub"}),(_vm.firstName)?_c('div',[_vm._v(" "+_vm._s(_vm.firstName)+" ")]):_vm._e()],2),(_vm.mobileStatus)?_c('div',{staticClass:"auth__text"},[_vm._v(" "+_vm._s(_vm.$t('global.entry'))+" ")]):_vm._e()]),_c('button',{staticClass:"landing-page__button landing-header__btn",on:{"click":function($event){return _vm.requestCall()}}},[_vm._v(" "+_vm._s(_vm.$t('global.requrestACall'))+" ")])],1)])]),_c('transition',{attrs:{"name":"fade-el"}},[(_vm.isVisibleRequestCallModal)?_c('requestCall',{on:{"closeRequestCallModal":_vm.closeRequestCallModal}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade-el"}},[(_vm.showSingUpModal)?_c('signupFast',{attrs:{"showAuth":true},on:{"closeSingUpModal":_vm.toggleSingUpModal}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }