const contacts = [
  {
    id: 1,
    icon: 'ub-icon-tel-ub',
    link: 'tel:+971521841181',
    text: '+971 52 184 1181',
  },
  {
    id: 2,
    icon: 'ub-icon-msg-ub',
    link: 'mailto:support@upperbs.com',
    text: 'support@upperbs.com',
  },
];

export default contacts;
