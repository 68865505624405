var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-field",class:_vm.customClass,on:{"click":function($event){$event.stopPropagation();}}},[(_vm.labelText)?_c('div',{staticClass:"text-field__label",class:_vm.onlyLabelClass},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e(),_c('div',{staticClass:"text-field-wrapper",class:[
      {'text-field-wrapper_error': _vm.errorStatus},
      {'text-field-wrapper_disabled': _vm.disabled}
    ]},[((_vm.typeInput)==='checkbox'&&(!_vm.link))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"text-field__input",class:[
        {'text-field__input_padding': _vm.icon || _vm.clearButton},
        {'text-field__input_capitalize': _vm.textCapitalize},
        {'text-field__input_step': _vm.stepsField},
        _vm.onlyInputClass
      ],attrs:{"name":_vm.name,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"placeholder":_vm.placeholderText,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,_vm.value)>-1:(_vm.model)},on:{"focus":function($event){return _vm.inputFocusStatus(true)},"blur":function($event){return _vm.inputFocusStatus(false)},"keydown":function($event){return _vm.handleEventClick($event)},"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}):((_vm.typeInput)==='radio'&&(!_vm.link))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"text-field__input",class:[
        {'text-field__input_padding': _vm.icon || _vm.clearButton},
        {'text-field__input_capitalize': _vm.textCapitalize},
        {'text-field__input_step': _vm.stepsField},
        _vm.onlyInputClass
      ],attrs:{"name":_vm.name,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"placeholder":_vm.placeholderText,"readonly":_vm.readonly,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.model,_vm.value)},on:{"focus":function($event){return _vm.inputFocusStatus(true)},"blur":function($event){return _vm.inputFocusStatus(false)},"keydown":function($event){return _vm.handleEventClick($event)},"change":function($event){_vm.model=_vm.value}}}):(!_vm.link)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"text-field__input",class:[
        {'text-field__input_padding': _vm.icon || _vm.clearButton},
        {'text-field__input_capitalize': _vm.textCapitalize},
        {'text-field__input_step': _vm.stepsField},
        _vm.onlyInputClass
      ],attrs:{"name":_vm.name,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"placeholder":_vm.placeholderText,"readonly":_vm.readonly,"type":_vm.typeInput},domProps:{"value":_vm.value,"value":(_vm.model)},on:{"focus":function($event){return _vm.inputFocusStatus(true)},"blur":function($event){return _vm.inputFocusStatus(false)},"keydown":function($event){return _vm.handleEventClick($event)},"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}}):_c('div',{staticClass:"text-field__link"},[_c('a',{attrs:{"href":_vm.model,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.model)+" ")])]),(_vm.clearButton)?_c('div',{staticClass:"text-field-wrapper__circle",on:{"click":function($event){return _vm.clearText()}}},[_c('i',{staticClass:"ub-icon-close-circle"})]):_vm._e(),(_vm.customIcon)?_c('i',{staticClass:"text-field__icon",class:_vm.customIcon,on:{"click":function($event){return _vm.handleClickIcon($event)}}}):_vm._e(),(_vm.icon && _vm.model.length && !_vm.errorStatus)?_c('i',{staticClass:"text-field__icon",class:_vm.icon,on:{"click":function($event){return _vm.handleClickIcon($event)}}}):_vm._e(),(_vm.icon && _vm.typeInput === 'text' && _vm.model.length && !_vm.errorStatus)?_c('i',{staticClass:"ub-icon-eye-hidden text-field__icon",on:{"click":function($event){return _vm.handleClickIcon($event)}}}):_vm._e(),(_vm.errorStatus && !_vm.hideIconError)?_c('i',{staticClass:"ub-icon-info-valid text-field__icon text-field__icon_error"}):_vm._e(),(_vm.calendar && !_vm.errorStatus)?_c('i',{staticClass:"ub-icon-calendar text-field__icon",on:{"click":function($event){return _vm.handleClickIcon($event)}}}):_vm._e(),(_vm.time && !_vm.errorStatus)?_c('i',{staticClass:"ub-icon-clock text-field__icon",on:{"click":function($event){return _vm.handleClickIcon($event)}}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }