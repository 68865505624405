import * as types from '@/store/constant/mutationsTypes';

const state = {
  userInfo: {},
  currentService: {},
};

const getters = {
  userInfo: (state) => state.userInfo,
  currentService: (state) => state.currentService,
};

const actions = {
  setUserInfo({ commit }, data) {
    commit(types.SET_USER_INFO, data);
  },
  setCurrentService({ commit }, data) {
    commit(types.SET_CURRENT_SERVICE, data);
  },
};

const mutations = {
  [types.SET_USER_INFO](state, data) {
    state.userInfo = data;
  },
  [types.SET_CURRENT_SERVICE](state, data) {
    state.currentService = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
