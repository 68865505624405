import {
  OWNER, CEO, MANAGER, DEPARTMENT_HEAD,
} from '@/constants/roleType';

export default {
  computed: {
    isAdmin() {
      const user = this.$store.getters.userInfo;
      if (Object.keys(user).length) {
        const { role = '' } = user;
        return role === OWNER
            || role === CEO
            || role === MANAGER
            || role === DEPARTMENT_HEAD;
      }
      return '';
    },
  },
};
