<template>
  <div class="login-page">
    <div class="login-page-panel">
      <div class="login-page-panel__logo">
        <img
          src="@/assets/images/logo.svg"
          alt="logo"
        >
      </div>
      <h1 class="login-page-panel__title">
        {{ $t('loginPage.loginForm.loginFormTitle') }}
      </h1>
      <div class="admin-form-field">
        <textInput
          :labelText="$t('loginPage.loginForm.loginLabel')"
          :typeInput="'email'"
          :class="{'error': $validator.errors.has('email')}"
          :errorStatus="$validator.errors.has('email')"
          :autofocus="true"
          :value.sync="user.email"
          @changeInput="() => removeValidationError('email')"
        ></textInput>
        <transition name="fade-el">
          <span
            v-if="$validator.errors.has('email')"
            class="validation"
          >
            {{ $validator.errors.first('email') }}
          </span>
        </transition>
      </div>
      <div class="admin-form-field">
        <textInput
          :labelText="$t('loginPage.loginForm.passwordLabel')"
          :typeInput="!isVisiblePassword ? 'password' : 'text'"
          :errorStatus="$validator.errors.has('password')"
          :value.sync="user.password"
          :icon="!isVisiblePassword ? 'ub-icon-eye' : 'ub-icon-eye-hidden'"
          @handleClickIcon="showPassword"
          @handleEventClick="loginUser"
          @changeInput="() => removeValidationError('password')"
        ></textInput>
        <transition name="fade-el">
          <span
            v-if="$validator.errors.has('password')"
            class="validation"
          >
            {{ $validator.errors.first('password') }}
          </span>
        </transition>
      </div>
      <div class="login-page-panel__info">
        <checkBox
          :checkboxText="$t('loginPage.loginForm.saveMySettings')"
          :value.sync="rememberMe"
        />
        <div
          v-if="false"
          class="login-page__text login-page-panel__info-text"
        >
          {{ $t('loginPage.loginForm.forgotPassword') }}
        </div>
      </div>
      <transition name="fade-el">
        <div
          v-if="validationError.status"
          class="login-page-panel__error"
        >
          {{ validationError.text }}
        </div>
      </transition>
      <div
        class="login-page-panel__footer"
      >
        <div
          v-if="false"
          class="login-page__text"
        >
          {{ $t('loginPage.loginForm.loginAccount') }}
        </div>
        <button
          class="ub-btn ub-btn_primary"
          @click="loginUser"
        >
          {{ $t('global.btnLogin') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import checkBox from '@/components/fields/checkBox';
import userApi from '@/api/user/userApi';
import validationErrorMessage from '@/locales/validationErrorMessage';
import {
  CLIENT, PARTNER,
  DEPARTMENT_HEAD, MANAGER,
} from '@/constants/roleType';
import { UB_CONTENT, UB_BUSINESS_DEVELOPMENT } from '@/constants/listStaffDepartments';

export default {
  name: 'LoginPage',
  components: {
    textInput,
    checkBox,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isVisiblePassword: false,
      rememberMe: false,
      validationError: {
        text: '',
        status: false,
      },
      validator: {},
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
  },
  beforeMount() {
    const dict = {
      en: {
        custom: {
          email: {
            required: validationErrorMessage.en.inputRequired,
          },
          password: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          email: {
            required: validationErrorMessage.ru.inputRequired,
          },
          password: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'email', rules: { required: true } });
    this.$validator.attach({ name: 'password', rules: { required: true } });
    const token = localStorage.getItem('token');
    if (token) {
      const { role } = this.userInfo;
      if (Object.keys(this.userInfo).length === 0) {
        userApi.currentUserInfo()
          .then((resp) => {
            const { role } = resp.data;
            if (role === CLIENT || role === PARTNER) {
              this.$router.push({
                name: `landing-${this.language}`,
                query: {},
              });
            } else {
              this.$router.push({ name: 'country' });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (role === CLIENT || role === PARTNER) {
        this.$router.push({
          name: 'page404',
          query: {},
        });
      } else {
        this.$router.push({ name: 'country' });
      }
    }
  },
  methods: {
    removeValidationError(error) {
      if (this.$validator.errors && this.$validator.errors.items) {
        const fieldError = this.$validator.errors.items.find((item) => item.field === error);
        if (fieldError) {
          this.$validator.errors.removeById(fieldError.id);
        }
      }
    },
    loginUser() {
      this.$validator.validateAll({
        email: this.user.email,
        password: this.user.password,
      }).then((result) => {
        if (result) {
          userApi.login(this.user).then((resp) => {
            const { role } = resp.data;
            if (role === CLIENT || role === PARTNER) {
              this.$router.push({
                name: `landing-${this.language}`,
              });
            } else {
              const { token } = resp.data;
              this.$store.dispatch('setUserInfo', resp.data);
              localStorage.setItem('token', JSON.stringify(token));
              localStorage.setItem('rememberMe', this.rememberMe);
              if ((this.userRole === DEPARTMENT_HEAD || this.userRole === MANAGER)
                  && this.userDepartment !== UB_CONTENT && this.userDepartment !== UB_BUSINESS_DEVELOPMENT) {
                this.$router.push({ name: 'clients' });
              } else if (this.userDepartment === UB_BUSINESS_DEVELOPMENT) {
                this.$router.push({ name: 'partners' });
              } else {
                this.$router.push({ name: 'country' });
              }
            }
          }).catch((err) => {
            if (err.response && (err.response.data.statusCode === 400 || err.response.data.statusCode === 401
            || err.response.data.statusCode === 404
            )) {
              this.validationError = {
                status: true,
                text: this.$t('loginPage.loginForm.loginValidation'),
              };
              setTimeout(() => {
                this.validationError = {
                  status: false,
                  text: '',
                };
              }, 3000);
            }
          });
        }
      });
    },
    showPassword() {
      this.isVisiblePassword = !this.isVisiblePassword;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/variables";

.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__text {
    font: $font-size-base $font-global-medium;
    color: $color-dodger-blue;
    transition: opacity .15s ease-in;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  &-panel {
    width: 420px;
    height: 490px;
    background: $color-white;
    border: 1px solid $color-gallery;
    border-radius: $borderRadius;
    padding: 40px 28px 24px;

    &__logo {
      display: flex;
      justify-content: center;
      margin-bottom: 38px;
    }

    &__title {
      text-align: center;
      font-size: 22px;
      line-height: 25px;
      margin-bottom: 15px;
      font: bold $font-size-h1 $font-global;
      line-height: 1.136;
    }

    &__error {
      margin-top: 20px;
      font: $font-size-h3 $font-global;
      color: $color-cardinal;
      position: absolute;
    }

    &__footer {
      margin-top: 105px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &_signup {
        margin-top: 31px;
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      &-text {
        font: $font-size-md $font-global-medium;
      }
    }
  }
}
</style>
