<template>
  <footer class="landing-page-footer">
    <div class="landing-page__container">
      <div class="landing-page-footer-row">
        <div class="landing-page-footer-row__info">
          <div class="landing-page-footer-row__info-logo">
            <transition
              name="fade-el"
              mode="out-in"
              appear
            >
              <v-lazy-image
                :src="require('@/assets/images/logo-light.svg')"
                :srcPlaceholder="require('@/assets/images/landing/phImage.svg')"
                alt="logo"
                @click="proceedTo()"
              />
            </transition>
          </div>
          <div class="landing-page-footer-row__info-text">
            {{ $t('landingPage.footerSection.description') }}
          </div>
        </div>
        <div class="landing-page-footer-row-right">
          <div
            class="landing-page-footer-row-right__social"
          >
            <div
              v-for="social in socials"
              :key="social.id"
              class="landing-page-footer-row-right__social-item"
            >
              <a
                rel="noopener noreferrer"
                :aria-label="social.name"
                @click="openNewWindow(social.link)"
              >
                <i :class="social.icon"></i>
              </a>
            </div>
          </div>
          <div class="landing-page-footer-row-right__contacts">
            <div
              v-for="contact in contacts"
              :key="contact.id"
              class="landing-page-footer-row-right__contacts-item"
            >
              <template v-if="contact.link">
                <a
                  :href="contact.link"
                >
                  {{ contact.text }}
                </a>
              </template>
              <template v-else>
                <span>
                  {{ $t(contact.text) }}
                </span>
              </template>
              <i
                :class="contact.icon"
              >
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import socials from '@/constants/landing/socials';
import contacts from '@/constants/landing/contacts';

export default {
  name: 'FooterTemplate',
  data: () => ({
    socials,
    contacts,
  }),
  methods: {
    proceedTo() {
      this.$router.push('/');
    },
    openNewWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/mixins";

  .landing-page-footer {
    padding: 15px;
    min-height: 180px;
    max-height: 180px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    background: url('../../assets/images/landing/questions-bg.png') no-repeat;
    background-size: cover;
    background-position: 50% 100%;

    @include mobile {
      padding-top: 40px;
      padding-bottom: 35px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__info {
        display: flex;
        max-width: 450px;
        width: 100%;

        @include tablet-landscape {
          flex-wrap: wrap;
          width: 25%;
        }

        @include mobile {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          align-items: center;

        }

        &-text {
          padding-left: 23px;
          max-width: 150px;
          font: $font-size-md $font-landing-thin;
          line-height: 1.4;
          color: $color-white;
          position: relative;
          top: -9px;

          @include tablet-landscape {
            padding-left: 0;
            margin-top: 18px;
          }

          @include tablet-portrait {
            text-align: center;
          }

          @include mobile {
            margin-top: 0;
          }
        }

        &-logo {
          padding-right: 15px;
          position: relative;

          @include mobile {
            padding-right: 0;
            margin-bottom: 10px;
          }

          img {
            width: 230px;
            max-height: 40px;
            cursor: pointer;
          }
        }
      }

      &-right {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 685px;
        position: relative;
        top: -5px;

        @include tablet-landscape {
          flex-wrap: wrap;
          width: 75%;
        }

        @include mobile {
          width: 100%;
        }

        &__social {
          display: flex;
          margin: 0 10px;

          @include tablet-landscape {
            justify-content: flex-end;
            margin-left: auto;
            margin-top: 0;
            margin-right: 50px;
          }

          @include tablet-portrait {
            justify-content: center;
            width: 100%;
            order: 0;
          }

          @include mobile {
            margin-top: 17px;
          }

          &-item {
            margin: 0 5px;
            cursor: pointer;

            @include tablet-landscape {
              &:last-child {
                margin-right: 0;
              }
            }
          }

          i {
            font-size: $font-size-icon-large;
            color: $color-shark-dark;
            transition: color .15s ease-in;

            &:before {
              margin: 0;
            }

            &:hover {
              color: $color-denim;
            }
          }
        }

        &__contacts {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          position: relative;
          top: -5px;

          @include tablet-landscape {
            padding-right: 0;
            top: 0;
          }

          @include tablet-portrait {
            text-align: center;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 20px;
            order: 1;
          }

          @include mobile {
            justify-content: center;
            margin-top: 23px;
          }

          i {
            color: $color-dodger-blue-dark;
            font-size: 19px;
          }

          a {
            font: $font-size-landing-sm $font-landing-global;
            text-decoration: none;
            color: $color-white;
            transition: color .15s ease-in;
            display: inline-block;
            margin-right: 15px;

            &:hover {
              color: $color-denim;
            }
          }

          &-item {

            i {
              &:before {
                margin: 0;
              }
            }

            & + .landing-page-footer-row-right__contacts-item {
              margin-top: 6px;

              i {
                font-size: $font-size-ml;
                position: relative;
                top: 1px;
                left: -5px;
              }
            }
          }
        }
      }
    }
  }

@include tablet-portrait {
  .landing-page-footer-row-right {
    &__social, &__contacts {
      margin-top: 0;
    }

    &__social {
      width: auto;
      margin: auto;
    }
  }
}

@include mobile {
  .landing-page-footer {
    max-height: none;
    min-height: auto;
  }

  .landing-page-footer-row {
    flex-direction: column;

    &__info {
      max-width: 100%;
      width: 100%;

      &-text {
        margin-bottom: 15px;
      }
    }

    &-right {
      flex-direction: column;

      &__social {
        margin-bottom: 15px;
      }

      &__contacts {
        align-items: center;
      }
    }
  }
}
</style>
