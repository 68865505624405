import Vue from 'vue';
import Vuex from 'vuex';
import userInfo from '@/store/modules/userInfo';
import countries from '@/store/modules/countries';
import typeServices from '@/store/modules/type-services';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userInfo,
    countries,
    typeServices,
  },
});
