<template>
  <div class="login-page-fast">
    <div class="login-page-fast-panel">
      <h1 class="login-page-fast-panel__title">
        {{ $t('loginPage.loginForm.loginFormTitle') }}
      </h1>
      <div class="admin-form-field">
        <textInput
          :labelText="$t('loginPage.loginForm.loginLabel')"
          :onlyLabelClass="'text-field__label_landing'"
          :typeInput="'email'"
          :onlyInputClass="'text-field__input_landing'"
          :class="{'error': $validator.errors.has('email')}"
          :errorStatus="$validator.errors.has('email')"
          :autofocus="true"
          :value.sync="user.email"
          :customClass="'text-field_alt'"
          @changeInput="() => removeValidationError('email')"
        >
        </textInput>
        <transition name="fade-el">
          <span
            v-if="$validator.errors.has('email')"
            class="validation"
          >
            {{ $validator.errors.first('email') }}
          </span>
        </transition>
      </div>
      <div class="admin-form-field">
        <textInput
          :onlyInputClass="'text-field__input_landing'"
          :onlyLabelClass="'text-field__label_landing'"
          :labelText="$t('loginPage.loginForm.passwordLabel')"
          :typeInput="!isVisiblePassword ? 'password' : 'text'"
          :errorStatus="$validator.errors.has('password')"
          :value.sync="user.password"
          :icon="!isVisiblePassword ? 'ub-icon-eye' : 'ub-icon-eye-hidden'"
          :customClass="'text-field_alt'"
          @handleClickIcon="showPassword"
          @handleEventClick="loginUser"
          @changeInput="() => removeValidationError('password')"
        >
        </textInput>
        <transition name="fade-el">
          <span
            v-if="$validator.errors.has('password')"
            class="validation"
          >
            {{ $validator.errors.first('password') }}
          </span>
        </transition>
      </div>
      <div class="login-page-fast-panel__info">
        <checkBox
          :checkboxText="$t('loginPage.loginForm.saveMySettings')"
          :value.sync="rememberMe"
          :customClass="'ub-checkbox_landing'"
        />
        <div
          v-if="false"
          class="login-page-fast__text login-page-fast-panel__info-text"
        >
          {{ $t('loginPage.loginForm.forgotPassword') }}
        </div>
      </div>
      <transition name="fade-el">
        <div
          v-if="validationError.status"
          class="login-page-fast-panel__error"
        >
          {{ validationError.text }}
        </div>
      </transition>
      <div
        class="login-page-fast-panel__footer"
        :class="{'login-page-fast-panel__footer_end': !service}"
      >
        <div
          v-if="service"
          class="login-page-fast__text"
          @click="showCreateFastWindow"
        >
          {{ $t('loginPage.loginForm.quickRegistration') }}
        </div>
        <button
          class="ub-btn ub-btn_primary login-page-fast__btn"
          @click="loginUser"
        >
          {{ $t('global.btnLogin') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import checkBox from '@/components/fields/checkBox';
import userApi from '@/api/user/userApi';
import validationErrorMessage from '@/locales/validationErrorMessage';
import {
  CLIENT, OWNER, PARTNER, CEO,
} from '@/constants/roleType';

export default {
  name: 'LoginPage',
  components: {
    textInput,
    checkBox,
  },
  props: {
    service: {
      type: Object,
      default: () => {},
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isVisiblePassword: false,
      rememberMe: false,
      validationError: {
        text: '',
        status: false,
      },
      validator: {},
    };
  },
  beforeMount() {
    const token = localStorage.getItem('token');
    if (token) {
      userApi.currentUserInfo()
        .then((resp) => {
          this.$store.dispatch('setUserInfo', resp.data);
          const { data: { role } } = resp;
          if (role === OWNER || role === CEO) {
            this.$router.push({ name: 'country' });
          } else {
            this.$router.push({
              name: 'ordersList',
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    const dict = {
      en: {
        custom: {
          email: {
            required: validationErrorMessage.en.inputRequired,
          },
          password: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          email: {
            required: validationErrorMessage.ru.inputRequired,
          },
          password: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'email', rules: { required: true } });
    this.$validator.attach({ name: 'password', rules: { required: true } });
  },
  methods: {
    showCreateFastWindow() {
      this.$emit('showCreateFastWindow');
    },
    removeValidationError(error) {
      if (this.$validator.errors && this.$validator.errors.items) {
        const fieldError = this.$validator.errors.items.find((item) => item.field === error);
        if (fieldError) {
          this.$validator.errors.removeById(fieldError.id);
        }
      }
    },
    loginUser() {
      this.$validator.validateAll({
        email: this.user.email,
        password: this.user.password,
      }).then((result) => {
        if (result) {
          this.$emit('update:loader', true);
          userApi.login(this.user).then((resp) => {
            this.$store.dispatch('setUserInfo', resp.data);
            const { token, role } = resp.data;
            const idUser = resp.data._id;
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('rememberMe', this.rememberMe);
            if (this.service && (resp.data.role === CLIENT)) {
              this.$emit('newOrder', idUser);
            } else if (role !== CLIENT && role !== PARTNER) {
              this.$router.push({
                name: 'adminOrderList',
              });
            } else {
              this.$emit('update:loader', false);
              this.$emit('closeSingUpModal');
              this.$router.push({
                name: 'ordersList',
              });
            }
          }).catch((err) => {
            this.$emit('update:loader', false);
            if (err.response && (err.response.data.statusCode === 400 || err.response.data.statusCode === 401
            || err.response.data.statusCode === 404
            )) {
              this.validationError = {
                status: true,
                text: this.$t('loginPage.loginForm.loginValidation'),
              };
              setTimeout(() => {
                this.validationError = {
                  status: false,
                  text: '',
                };
              }, 3000);
            }
          });
        }
      });
    },
    showPassword() {
      this.isVisiblePassword = !this.isVisiblePassword;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";
@import "src/sass/mixins";

.login-page-fast {

  &__text {
    font: $font-size-base $font-landing-medium;
    color: $color-dodger-blue;
    transition: opacity .15s ease-in;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  &__btn {
    height: 53px;
    width: 104px;
    font-family: $font-landing-medium;
    border-radius: $borderRadiusLarger;
  }

  &-panel {
    margin-top: 40px;

    &__title {
      text-align: center;
      font-size: $font-size-huge;
      line-height: 25px;
      margin-bottom: 15px;
      font: $font-size-h1 $font-landing-heavy;
      line-height: 1.136;
    }

    &__error {
      margin-top: 20px;
      font: $font-size-sm $font-global;
      color: $color-cardinal;
      position: absolute;
    }

    &__footer {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile-middle {
        margin-top: 34px;
      }

      &_end {
        justify-content: flex-end;
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      &-text {
        font: $font-size-md $font-global-medium;
      }
    }
  }
}
</style>
