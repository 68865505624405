const socials = [
  {
    id: 1,
    icon: 'ub-icon-instagram',
    name: 'instagram',
    link: 'https://instagram.com/upper.business?igshid=etg5gchmoha3',
  },
  {
    id: 2,
    icon: 'ub-icon-facebook',
    name: 'facebook',
    link: 'https://www.facebook.com/Upperbusiness-109117730938203',
  },
  {
    id: 3,
    icon: 'ub-icon-linkedin',
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/upperbusiness',
  },
  {
    id: 4,
    icon: 'ub-icon-youtube',
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCCh9f6sRFWMFktuWBZ99K_w',
  },
];

export default socials;
