import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  addOrder(service) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.post('orders', service);
  },
  getOrderById(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(`orders/${id}`);
  },
  getOrdersForPayoffs(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(`orders/orders-for-payoffs/?partner=${id}`);
  },
  getOrdersPartnerForPayoffs(id, orderId) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(`orders/orders-for-payoffs/${orderId}?partner=${id}`);
  },
  updateOrderById({ id, order }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`orders/${id}`, order);
  },
  getAllOrders({
    page = 1, perPage = 5, filter, search,
  }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    const searchQuery = search ? `&search=${search}` : '';
    const sortQuery = '&sort={"createdAt": -1}';
    return instWithCred.get(`orders?page=${page}&per_page=${perPage}${filterQuery}${searchQuery}${sortQuery}`);
  },
  getStatuses() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get('/orders/order-statuses');
  },
  updateOrder({ id, order }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`orders/${id}`, order);
  },
  updatePaymentStatus({ id, operation }) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`orders/update-payment-status/${id}`, operation);
  },
};
