<template>
  <div
    class="ub-dropdown"
    :class="customClass"
  >
    <div class="ub-dropdown__select">
      <div class="ub-dropdown__label">
        <div
          class="ub-dropdown__wrap"
          @click="toggleDropdown()"
        >
          <div class="ub-dropdown__image">
            <img
              :src="getOptionImage(dropDownValue)"
              :alt="dropDownValue"
            >
          </div>
          <span
            v-if="!hideText"
            class="ub-dropdown__text"
          >
            {{ dropDownValue }}
          </span>
          <template v-if="!arrowAlt">
            <i
              class="ub-icon-arrow ub-dropdown__arrow"
              :class="{'ub-dropdown__arrow_down': !visible}"
            >
            </i>
          </template>
          <template v-else>
            <i
              v-if="!hideArrow"
              class="ub-icon-rectangle ub-dropdown__arrow ub-dropdown__arrow_alt"
              :class="[
                {'ub-dropdown__arrow_down': !visible},
                {'ub-dropdown__arrow_up': visible}
              ]"
            >
            </i>
          </template>
        </div>
      </div>
    </div>
    <transition name="fade-el">
      <div
        class="ub-dropdown-modal"
        :class="!visible ? 'ub-dropdown-modal_hidden' : 'ub-dropdown-modal_visible'"
      >
        <div
          class="ub-dropdown-modal__list"
        >
          <div
            v-for="item in list"
            :key="item.code"
            class="ub-dropdown-modal__item"
            :class="{'ub-dropdown-modal__item_current' : item.code === dropDownValue}"
            @click="changeDropdown(item.code)"
          >
            <div class="ub-dropdown-modal__item-image">
              <img
                :src="getOptionImage(item.code)"
                :alt="item.code"
              >
            </div>
            <span>{{ item.text }}</span>
            <i
              v-if="item.code === dropDownValue"
              class="ub-icon-check-mark-select ub-dropdown-modal__item-icon"
            >
            </i>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'DropDown',
  props: {
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    customClass: {
      type: String,
    },
    arrowAlt: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data: () => ({
    visible: false,
  }),
  computed: {
    list() {
      return this.dropdownOptions.list;
    },
    dropDownValue() {
      return this.dropdownOptions.value;
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/countryLangs/${image}.svg`);
        /* eslint-enable */
      } catch (e) {
        return false;
      }
    },
    closeDropdown(e) {
      if (!this.$el.contains(e.target)) {
        this.visible = false;
      }
    },
    toggleDropdown() {
      this.visible = !this.visible;
    },
    changeDropdown(code) {
      this.$emit('changeDropdown', code);
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/mixins";

.ub-dropdown {
  min-width: 164px;
  max-width: 164px;
  text-align: right;
  position: relative;
  margin-right: 8px;

  &__image {
    max-width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    top: 1px;
    left: -8px;

    img {
      border-radius: 50%;
    }
  }

  &__wrap {
    min-width: 50px;
    cursor: pointer;
    transition: opacity .15s ease-in;
    display: flex;

    &:hover {
      opacity: .8;
    }
  }

  &__arrow {
    position: relative;
    top: 1px;
    color: $color-dodger-blue;
    left: -3px;

    &:before {
      transform: rotate(180deg);
    }

    &_down:before {
      transform: rotate(0);
    }

    &_alt {
      color: $color-white;
      font-size: 4px;
      top: -3px;
      left: -5px;

      &.ub-dropdown__arrow_up {
        left: -2px;
        top: -4px;
      }
    }
  }

  &__text {
    margin-right: 6px;
    display: inline-block;
    color: $color-dodger-blue;
    font: $font-size-h2 $font-global-medium;
    text-transform: capitalize;
  }

  &__label {
    display: flex;
    justify-content: flex-end;
  }

  &-modal {

    &_hidden {
      visibility: hidden;
    }

    &_visible {
      visibility: visible;
    }

    &__list {
      width: 100%;
      max-width: 170px;
      min-width: 170px;
      font-size: $font-size-xlg;
      position: absolute;
      z-index: 25;
      background: $color-white;
      box-shadow: 0 0 8px rgba($color-black, .06);
      border-radius: $borderRadius;
      text-align: left;
      padding: 2px 0;
      top: 24px;
      right: 6px;
    }

    &__item {
      padding: 11px 12px;
      font: $font-size-h3 $font-global;
      color: $color-black;
      position: relative;
      cursor: pointer;
      height: 42px;
      transition: background-color .15s ease-in;
      display: flex;
      align-items: center;

      span {
        position: relative;
        top: -1px;
        display: inline-block;
      }

      &-image {
        max-width: 16px;
        min-width: 16px;
        height: 16px;
        position: relative;
        left: -2px;
        top: -2px;
        margin-right: 4px;

        img {
          border-radius: 50%;
        }
      }

      &_current {
        background: $color-alabaster;
      }

      &-icon {
        position: absolute;
        right: 12px;
        top: 11px;
        color: $color-dodger-blue;
        font-size: $font-size-lg;
      }

      &:hover {
        background-color: $color-alabaster;
      }
    }
  }

  &_landing {
    padding-right: 9px;
    margin-left: 20px;
    position: relative;
    height: 100%;
    margin-right: 0;
    top: 10px;

    .ub-dropdown__select {
      height: 100%;
    }

    .ub-dropdown {
      &__image {
        max-width: 31px;
        min-width: 31px;
        height: 31px;
        border-radius: 50%;
        position: relative;
        top: 0;
        left: 0;
        margin-left: auto;

        @include tablet-portrait {
          max-width: 24px;
          min-width: 24px;
          height: 24px;
        }

        img {
          border-radius: 50%;
        }
      }
    }

    @include tablet-portrait {

      &:after {
        display: none;
      }
    }

    &_color {

      &:after {
        background: rgba($color-white, .07);
      }
    }

    .ub-dropdown {
      &__text {
        font: $font-size-md $font-landing-global;
        color: $color-white;
        text-transform: uppercase;
      }

      &__wrap {
        display: block;
      }

      &-modal {
        &__list {
          border-radius: 0;
          padding: 4px 0;
          right: 8px;
          top: 45px;
        }

        &__item {
          font-family: $font-landing-global;
          padding: 11px 14px;

          &_current {
            color: $color-dodger-blue;
            background: transparent;
          }

          &-icon {
            display: none;
          }
        }
      }
    }
  }
}

.landing-header_secondary {

  .ub-dropdown__text {
    color: $color-dodger-blue;
  }

  .ub-dropdown__arrow_alt {
    color: $color-dodger-blue;
  }
}

</style>
