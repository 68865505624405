<template>
  <div
    class="landing-header"
    :class="[
      {'landing-header_secondary': secondary},
      {'landing-header_mobile': toggleMenu}
    ]"
  >
    <div class="landing-page__container">
      <div class="landing-header__wrapper">
        <div
          class="landing-header__left"
          :class="{'landing-header__left_login': firstName}"
        >
          <a
            href="/"
            class="landing-header__logo"
            rel="noopener noreferrer"
            @click.prevent="goToPage('landing')"
          >
            <template v-if="!mobileStatus">
              <transition
                v-if="!secondary"
                name="fade-el"
                mode="out-in"
                appear
              >
                <v-lazy-image
                  :src="require('@/assets/images/logo.svg')"
                  :srcPlaceholder="require('@/assets/images/landing/phImage.svg')"
                  alt="logo"
                  title="Upper Business"
                />
              </transition>
              <transition
                v-else
                name="fade-el"
                mode="out-in"
                appear
              >
                <v-lazy-image
                  :src="require('@/assets/images/logo-landing.svg')"
                  :srcPlaceholder="require('@/assets/images/landing/phImage.svg')"
                  alt="logo"
                  title="Upper Business"
                />
              </transition>
            </template>
            <template v-else>
              <transition
                v-if="($route.name === `partner-${language}` || $route.name === `about-${language}`)
                  || ($route.name === `landing-${language}` && toggleMenu)
                  || ($route.name === `contacts-${language}` && toggleMenu)"
                name="fade-el"
                mode="out-in"
                appear
              >
                <v-lazy-image
                  :src="require('@/assets/images/logo-landing.svg')"
                  alt="logo"
                  title="Upper Business"
                  :srcPlaceholder="require('@/assets/images/landing/phImage.svg')"
                />
              </transition>
              <transition
                v-else
                name="fade-el"
                mode="out-in"
                appear
              >
                <v-lazy-image
                  :src="require('@/assets/images/logo.svg')"
                  alt="logo"
                  title="Upper Business"
                  :srcPlaceholder="require('@/assets/images/landing/phImage.svg')"
                />
              </transition>
            </template>
          </a>
          <input
            id="burg"
            type="checkbox"
            class="burger"
          >
          <label
            for="burg"
            class="btn-menu"
            :class="{'btn-menu_active': toggleMenu}"
            @click="toggleMenuMobile"
          >
            <span class="item"></span>
            <span class="item"></span>
            <span class="item"></span>
          </label>
        </div>
        <div class="landing-header__right">
          <ul class="landing-header-menu">
            <li
              v-for="itemMenu in landingHeaderMenu"
              :key="itemMenu.id"
              class="landing-header-menu__item"
              :class="[
                {'landing-header-menu__item_secondary': secondary},
                {'landing-header-menu__item_active': activeRoute === `${itemMenu.route}-${language}`},
                {'landing-header-menu__item_contacts': activeRoute === `contacts-${language}`}
              ]"
            >
              <span @click="goToPage(itemMenu.route)">
                {{ $t(itemMenu.text) }}
              </span>
            </li>
          </ul>
          <dropDown
            :dropdownOptions="dropdownOptions"
            :customClass="'ub-dropdown_landing'"
            :class="checkBgLine ? 'ub-dropdown_landing_color' : ''"
            :arrowAlt="true"
            :hideText="true"
            :hideArrow="true"
            @changeDropdown="changeLanguage"
          />
          <div
            class="auth"
            @click="toggleSingUpModal()"
          >
            <div class="auth__icon">
              <template v-if="Object.keys(userInfo).length">
                <div
                  v-if="!userAvatarUrl"
                  class="auth__user"
                >
                  <i class="ub-icon-user"></i>
                </div>
                <img
                  v-else-if="userAvatarUrl"
                  :src="userAvatarUrl"
                  class="auth__avatar"
                >
              </template>
              <i
                v-else
                class="ub-icon-user-ub"
              ></i>
              <div
                v-if="firstName"
              >
                {{ firstName }}
              </div>
            </div>
            <div
              v-if="mobileStatus"
              class="auth__text"
            >
              {{ $t('global.entry') }}
            </div>
          </div>
          <button
            class="landing-page__button landing-header__btn"
            @click="requestCall()"
          >
            {{ $t('global.requrestACall') }}
          </button>
        </div>
      </div>
    </div>
    <transition name="fade-el">
      <requestCall
        v-if="isVisibleRequestCallModal"
        @closeRequestCallModal="closeRequestCallModal"
      />
    </transition>
    <transition name="fade-el">
      <signupFast
        v-if="showSingUpModal"
        :showAuth="true"
        @closeSingUpModal="toggleSingUpModal"
      />
    </transition>
  </div>
</template>

<script>
import dropDown from '@/components/fields/dropDown';
import landingHeaderMenu from '@/constants/landingHeaderMenu';
import requestCall from '@/components/landing/modals/requestCall';
import { find, get } from 'lodash';
import signupFast from '@/components/modals/signupFast';
import MobileDetect from 'mobile-detect';
import {
  CLIENT, PARTNER, OWNER, MANAGER, CEO, DEPARTMENT_HEAD,
} from '@/constants/roleType';
import {
  UB_BUSINESS_DEVELOPMENT,
  UB_CONTENT,
} from '@/constants/listStaffDepartments';

export default {
  name: 'LandingHeader',
  components: {
    dropDown,
    requestCall,
    signupFast,
  },
  props: {
    secondary: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      showSingUpModal: false,
      toggleMenu: false,
      landingHeaderMenu,
      activeRoute: '',
      isVisibleRequestCallModal: false,
    };
  },
  computed: {
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    userRole() {
      const { role } = this.userInfo;
      return role;
    },
    isClient() {
      return this.userRole === CLIENT;
    },
    isOwner() {
      return this.userRole === OWNER || this.userRole === CEO;
    },
    isContentManager() {
      return this.userRole === MANAGER && this.userDepartment === UB_CONTENT;
    },
    isPartner() {
      return this.userRole === PARTNER;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userAvatarUrl() {
      return get(this.userInfo, 'userAvatarUrl', '');
    },
    firstName() {
      if (this.isPartner) {
        return get(this.userInfo, 'partnerCompanyName', '');
      }
      return get(this.userInfo, 'firstName', '');
    },
    mobileStatus() {
      const md = new MobileDetect(window.navigator.userAgent);
      const mobile = md.mobile();
      const tablet = md.tablet();
      return mobile !== null || tablet !== null;
    },
    checkBgLine() {
      const routerList = ['landing', 'about', 'partner'];
      const page = find(routerList, (item) => item === this.$route.name);
      return page !== undefined;
    },
    language: {
      get() {
        return this.$i18n.locale;
      },
      set(data) {
        this.$i18n.locale = data;
      },
    },
    dropdownOptions() {
      return {
        list: [
          {
            code: 'ru',
            text: 'Russian',
          },
          {
            code: 'en',
            text: 'English',
          },
        ],
        value: this.language,
      };
    },
  },
  watch: {
    $route(newRoute) {
      const { name = 'landing' } = newRoute;
      this.activeRoute = name;
    },
  },
  beforeMount() {
    this.activeRoute = this.$route.name;
  },
  methods: {
    toggleSingUpModal() {
      const token = localStorage.getItem('token');
      if (token) {
        if ((this.userRole === DEPARTMENT_HEAD || this.userRole === MANAGER)
            && this.userDepartment !== UB_CONTENT && this.userDepartment !== UB_BUSINESS_DEVELOPMENT) {
          this.$router.push({ name: 'clients' });
        } else if (this.userDepartment === UB_BUSINESS_DEVELOPMENT) {
          this.$router.push({ name: 'staff' });
        } else if (this.userRole === CLIENT || this.userRole === PARTNER) {
          this.$router.push({
            name: 'ordersList',
          });
        } else {
          this.$router.push({ name: 'country' });
        }
      } else {
        this.toggleMenu = false;
        this.showSingUpModal = !this.showSingUpModal;
      }
    },
    toggleMenuMobile() {
      this.toggleMenu = !this.toggleMenu;
    },
    closeRequestCallModal() {
      this.isVisibleRequestCallModal = false;
    },
    requestCall() {
      this.isVisibleRequestCallModal = true;
    },
    goToPage(route) {
      const language = this.$i18n.locale;
      this.toggleMenu = false;
      this.$router.push({
        name: `${route}-${language}`,
      });
    },
    changeLanguage(language) {
      this.language = language;
      this.$i18n.locale = language;
      const { name, query } = this.$route;
      const route = name.split('-');
      const [routeName] = route;
      this.$router.push({
        name: `${routeName}-${language}`,
        query,
      });
      localStorage.setItem('language', language);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../../sass/variables";
  @import "../../sass/mixins";

  .burger {
    display: none;
  }

  .landing-header {
    min-height: 97px;
    max-height: 97px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    &__btn {
      max-width: 200px;
      width: 100%;
      height: 51px;
      position: relative;
      top: -2px;
    }

    &_secondary {

      .landing-page__button {
        background: transparent;
        color: $color-white;
        border-color: $color-white;
        transition: all .15s ease-in;

        &:hover {
          background: $color-white;
          color: $color-dodger-blue;
          opacity: 1;
        }
      }

      .auth__icon i {
        color: $color-white;
      }

      .landing-page__button_transparent {
        border-color: $color-white;
        color: $color-white;
        transition: background-color .15s ease-in, color .15s ease-in;
        position: relative;
        top: 1px;

        &:hover {
          background: $color-dodger-blue;
          color: $color-white;
        }
      }
    }

    &__logo {
      cursor: pointer;

      img {
        width: 250px;
        max-height: 42px;
      }
    }

    &__button {
      max-width: 177px;
      min-width: 177px;
      border: 2px solid $color-white;
      font-family: $font-landing-medium;
      color: $color-white;
      padding: 0 5px;
      background: transparent;
      transition: all .15s ease-in;

      &:hover {
        background: $color-white;
        color: $color-dodger-blue;
      }
    }

    &__left {
      max-width: 450px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-right: 29px;

      &_login {
        max-width: 355px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: space-between;
    }

    &__wrapper {
      display: flex;
    }

    &-menu {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      position: relative;
      top: -2px;

      &__item {

        & + .landing-header-menu__item {
          margin-left: 30px;
        }

        span {
          color: $color-ebony-clay;
          font: $font-size-lg $font-landing-semibold;
          cursor: pointer;
          position: relative;
          white-space: nowrap;

          &:after {
            position: absolute;
            bottom: -41px;
            left: 0;
            content: '';
            width: 0;
            height: 2px;
            background: $color-royal-blue-dark;
            transition: width .1s ease-in;
          }
        }

        &_secondary {

          span {
            color: $color-white;

            &:after {
              background: $color-white;
            }
          }
        }

        &:hover {
          span {

            &:after {
              width: 100%;
            }
          }
        }

        &_active {
          position: relative;

          span {

            &:after {
              width: 100%;
            }
          }
        }

        &_contacts {

          span {

            &:after {
              background: $color-dodger-blue;
            }
          }

          &:after {
            background: $color-dodger-blue;
          }
        }
      }
    }
  }

  .auth {
    position: relative;
    top: 0;
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: opacity .15s ease-in;
    padding-top: 1px;

    &__avatar {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      border: 1px solid $color-blue-ribbon;
      margin: 0 5px;
    }

    &__user {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background: $color-blue-ribbon;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;

      .ub-icon-user {
        color: $color-white;
        font-size: $font-size-icon-ml;
      }
    }

    &:hover {
      opacity: .7;
    }

    &__icon {
      border-radius: 50%;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-landing-global;

      i {
        color: $color-mine-shaft-light;
        font-size: $font-size-landing-heading-tablet;
        margin: 0;
      }
    }

    &__text {
      font-family: $font-landing-medium;
      color: $color-white;
      font-size: $font-size-xlg;
      position: relative;
      top: 2px;
    }
  }

  @include tablet-landscape {
    .landing-header {
      &__left {
        margin-right: 35px;
        max-width: none;
        width: auto;
      }

      &-menu__item {
        span {
          &:after {
            bottom: -36px;
          }
        }
      }
    }
  }

  @include desktop-sm {
    .landing-header {
      &__left {
        margin-right: 130px;

        &_login {
          margin-right: 100px;
        }
      }
    }
  }

  @include desktop-header-sm {
    .landing-header {
      max-height: 125px;
      min-height: 125px;
    }
  }

  @include desktop-header {
    .landing-header {
      max-height: 97px;
      min-height: 97px;
    }
  }

  @include only-tablet-landscape {
    .landing-header {
      .ub-dropdown {
        &_landing {
          min-width: auto;
          max-width: none;
        }
      }
    }
  }

  @include tablet-portrait {
    .landing-page {
      &__button {
        display: none;
      }
    }

    .landing-header {
      &__btn {
        border-color: $color-white;
        color: $color-white;
        transition: all .15s ease-in;

        &:hover {
          opacity: 1;
          background: $color-white;
          color: $color-dodger-blue;
        }
      }

      &_mobile {
        .landing-header-menu {
          &__item {
            span {
              color: $color-white;
            }
          }
        }

        .auth__icon {
          i {
            color: $color-white;
          }

          div {
            color: $color-white;
            max-width: 300px;
          }
        }
      }

      .btn-menu_active {
        span {
          background: $color-white;
        }
      }
    }

    .ub-dropdown {
      &_landing {
        top: -10px;
        margin-right: 0;
        padding-right: 0;
        border-right: none;
        position: absolute;
        right: 15px;
      }
    }

    .landing-header {
      min-height: 50px;
      max-height: 50px;
      align-items: center;

      .auth {
        display: none;
      }

      &__logo {
        position: absolute;
        left: 50%;
        top: -12px;
        transform: translateX(-50%);
        max-width: 168px;
        min-width: 168px;
        z-index: 100;

        @include mobile-start {
          left: 50%;
        }
      }

      &-menu {
        display: none;
      }

      &_mobile {
        background: $color-dodger-blue !important;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        max-height: none;
        align-items: flex-start;
        padding-top: 25px;

        .auth {
          display: flex;
          position: relative;
          top: 140px;
        }

        .landing-page {
          &__button {
            display: flex;
          }
        }

        .ub-dropdown {
          &_landing {
            display: none;
          }
        }

        .landing-header {
          &__wrapper {
            flex-direction: column;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              bottom: -40px;
              left: 50%;
              transform: translateX(-50%);
              max-width: 320px;
              width: 100%;
              background: rgba($color-royal-blue, .12);
              height: 1px;
            }
          }

          &__right {
            flex-direction: column;
          }

          &-menu {
            display: flex !important;
            flex-direction: column;
            margin-top: 103px;
            margin-bottom: 24px;

            &__item {
              span {
                font: $font-size-landing-medium $font-landing-medium;

                &:after {
                  bottom: -15px;
                }
              }

              & + .landing-header-menu__item {
                margin-left: 0;
                margin-top: 28px;
              }
            }
          }

          &__button {
            display: flex !important;
          }
        }
      }

      &_secondary {

        .landing-page__button {
          border-color: $color-white;
        }

        .btn-menu {
          span {
            background: $color-white;
          }
        }
      }
    }

    .btn-menu {
      background: transparent;
      position: absolute;
      cursor: pointer;
      z-index: 3;
      width: 20px;
      height: 20px;
      top: -6px;
      left: 15px;

      span {
        position: absolute;
        border-radius: 5px;
        left: 0;
        top: 0;
        height: 3px;
        transition: top .15s ease-in, opacity .05s ease-in, transform .15s ease-in;
        background: $color-royal-blue;
        width: 100%;
        z-index: 5;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: 7px;
        }

        &:last-child {
          top: 14px;
        }
      }

      &_active {
        width: 26px;
        height: 26px;
        left: 0;

        span {
          height: 4px;
        }
      }

      &_active {

        span:first-child{
          transform: rotate(45deg);
          top: 6px;
          left: -7px;
        }

        span:nth-child(2) {
          opacity: 0;
        }

        span:last-child {
          transform: rotate(-45deg);
          top: 6px;
          left: -7px;
        }
      }
    }
  }
</style>
