import Vue from 'vue';
import VueRouter from 'vue-router';
import loginPage from '@/pages/loginPage.vue';
import store from '@/store/index';
import userApi from '@/api/user/userApi';
import {
  DEPARTMENT_HEAD, OWNER, CEO, CLIENT, PARTNER, MANAGER,
} from '@/constants/roleType';
import {
  UB_CONTACT, UB_CLIENT, UB_CONTENT, UB_BUSINESS_DEVELOPMENT,
} from '@/constants/listStaffDepartments';

const landing = () => import(/* webpackChunkName: 'landing' */ '@/pages/landing');
const service = () => import('@/pages/jurisdiction');
const compare = () => import(/* webpackChunkName: 'compare' */ '@/pages/compare');
const order = () => import(/* webpackChunkName: 'order' */ '@/pages/order');
const contacts = () => import(/* webpackChunkName: 'landing' */ '@/pages/contacts');
const about = () => import(/* webpackChunkName: 'landing' */ '@/pages/about');
const page404 = () => import(/* webpackChunkName: 'landing' */ '@/pages/page404');
const profile = () => import('@/pages/profile');
const others = () => import('@/pages/others');
const typeServices = () => import('@/pages/typeServices');
const clients = () => import(/* webpackChunkName: 'adminPanel' */ '@/pages/clients');
const client = () => import('@/pages/client');
const country = () => import('@/pages/country');
const reports = () => import('@/pages/reports');
const staff = () => import(/* webpackChunkName: 'adminPanel' */ '@/pages/staff');
const services = () => import(/* webpackChunkName: 'adminPanel' */ '@/pages/services');
const partners = () => import(/* webpackChunkName: 'adminPanel' */ '@/pages/partners');
const partnerForAdmin = () => import('@/pages/partnerForAdmin');
const partner = () => import(/* webpackChunkName: 'landing' */ '@/pages/partner');
const settings = () => import(/* webpackChunkName: 'adminPanel' */ '@/pages/settings');
const payments = () => import(/* webpackChunkName: 'adminPanel' */ '@/pages/payments');
const selectionService = () => import('@/pages/selectionService');
const ordersList = () => import('@/pages/ordersList');

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'loginPage',
    component: loginPage,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/orders-list',
    name: 'ordersList',
    component: ordersList,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru/service',
    name: 'service-ru',
    component: service,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/en/service',
    name: 'service-en',
    component: service,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/admin',
    redirect: '/admin/country',
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
    },
  },
  {
    path: '/admin/orders-list',
    name: 'adminOrderList',
    component: ordersList,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/admin/clients',
    name: 'clients',
    component: clients,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO, DEPARTMENT_HEAD],
      departments: [],
    },
  },
  {
    path: '/admin/client/:id',
    name: 'client',
    component: client,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO, MANAGER, DEPARTMENT_HEAD],
      departments: [UB_CLIENT, UB_CONTACT],
    },
  },
  {
    path: '/admin/partners',
    name: 'partners',
    component: partners,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO],
      departments: [],
    },
  },
  {
    path: '/admin/partner-for-admin/:id',
    name: 'partnerForAdmin',
    component: partnerForAdmin,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO],
      departments: [],
    },
  },
  {
    path: '/admin/country',
    name: 'country',
    component: country,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO, DEPARTMENT_HEAD, MANAGER],
      departments: [UB_CONTENT],
    },
  },
  {
    path: '/admin/reports',
    name: 'reports',
    component: reports,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO, DEPARTMENT_HEAD],
      departments: [UB_CONTACT],
    },
  },
  {
    path: '/admin/services',
    name: 'services',
    component: services,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
    },
  },
  {
    path: '/admin/settings',
    name: 'settings',
    component: settings,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO, DEPARTMENT_HEAD],
      departments: [UB_CLIENT],
    },
  },
  {
    path: '/admin/staff',
    name: 'staff',
    component: staff,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO, DEPARTMENT_HEAD],
      departments: [],
    },
  },
  {
    path: '/admin/payments',
    name: 'payments',
    component: payments,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO],
      departments: [],
    },
  },
  {
    path: '/admin/type-services',
    name: 'typeServices',
    component: typeServices,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO],
      departments: [],
    },
  },
  {
    path: '/admin/others',
    name: 'others',
    component: others,
    meta: {
      title: 'UPPERBUSINESS - Admin Panel',
      roles: [OWNER, CEO],
      departments: [],
    },
  },
  {
    path: '/en',
    name: 'landing-en',
    component: landing,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru',
    name: 'landing-ru',
    component: landing,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/en/contacts',
    name: 'contacts-en',
    component: contacts,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru/contacts',
    name: 'contacts-ru',
    component: contacts,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru/about',
    name: 'about-ru',
    component: about,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/en/about',
    name: 'about-en',
    component: about,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/en/partners',
    name: 'partner-en',
    component: partner,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru/partners',
    name: 'partner-ru',
    component: partner,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru/compare',
    name: 'compare-ru',
    component: compare,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/en/compare',
    name: 'compare-en',
    component: compare,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/ru/selection-service',
    name: 'selectionService-ru',
    component: selectionService,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/en/selection-service',
    name: 'selectionService-en',
    component: selectionService,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '*',
    name: 'page404',
    component: page404,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      description: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/order',
    name: 'order',
    component: order,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
    },
  },
  {
    path: '/admin/order',
    name: 'adminOrder',
    component: order,
    meta: {
      title: 'UPPERBUSINESS - Global Business Services',
      roles: [OWNER, CEO, DEPARTMENT_HEAD, MANAGER],
      departments: [UB_BUSINESS_DEVELOPMENT],
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const { roles } = to.meta;
  if (roles) {
    const { userInfo } = store.getters;
    if (Object.keys(userInfo).length) {
      const { role } = userInfo;
      if (roles.includes(role) || role !== CLIENT || role !== PARTNER) {
        next();
      } else next('404');
    } else if (localStorage.getItem('token')) {
      userApi.currentUserInfo()
        .then((resp) => {
          store.dispatch('setUserInfo', resp.data);
          const { role } = resp.data;
          if (role === CLIENT || role === PARTNER) {
            next('404');
          }
        })
        .catch((err) => {
          console.error(err);
          next('404');
        });
      next();
    } else {
      next('404');
    }
  } else {
    next();
  }
});

export default router;
