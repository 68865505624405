export const API_VERSION = 'v1';
export const CURRENT_SERVER = window.location.hostname === 'localhost'
  ? 'http://localhost:3000/'
  : `${window.location.protocol}//${window.location.hostname}/`;
export const AMOCONNECT_SERVER = `${window.location.protocol}//amoconnect.ru/amo-ipravo/`;
export const AUTHORIZATION = `${CURRENT_SERVER}api/${API_VERSION}/auth/`;
export const USERS_API = `${CURRENT_SERVER}api/${API_VERSION}/users/`;
export const COUNTRY_API = `${CURRENT_SERVER}api/${API_VERSION}/`;
export const SERVICES_API = `${CURRENT_SERVER}api/${API_VERSION}/`;
export const PARTNERPAYOFFS_API = `${CURRENT_SERVER}api/${API_VERSION}/`;
export const MAILER_API = `${CURRENT_SERVER}api/${API_VERSION}/mailer/`;
export const TYPE_SERVICES_API = `${CURRENT_SERVER}api/${API_VERSION}/`;
export const UPLOAD_API = `${CURRENT_SERVER}api/${API_VERSION}/upload-file`;
export const PAYMENTS_API = `${CURRENT_SERVER}api/${API_VERSION}/order-payments`;
export const CHAT_API = `${CURRENT_SERVER}api/${API_VERSION}/chats`;
export const MESSAGES_API = `${CURRENT_SERVER}api/${API_VERSION}/chatmessages`;
export const FORMS_API = `${CURRENT_SERVER}api/${API_VERSION}/`;
export const FORM_API = `${CURRENT_SERVER}api/${API_VERSION}/forms/`;
export const FEEDBACK_API = `${CURRENT_SERVER}api/${API_VERSION}/feedbacks/`;
