<template>
  <div
    class="text-field"
    :class="customClass"
    @click.stop
  >
    <div
      v-if="labelText"
      class="text-field__label"
      :class="onlyLabelClass"
    >
      {{ labelText }}
    </div>
    <div
      class="text-field-wrapper"
      :class="[
        {'text-field-wrapper_error': errorStatus},
        {'text-field-wrapper_disabled': disabled}
      ]"
    >
      <input
        v-if="!link"
        ref="input"
        v-model="model"
        :name="name"
        :type="typeInput"
        :disabled="disabled"
        class="text-field__input"
        :autofocus="autofocus"
        :placeholder="placeholderText"
        :value="value"
        :readonly="readonly"
        :class="[
          {'text-field__input_padding': icon || clearButton},
          {'text-field__input_capitalize': textCapitalize},
          {'text-field__input_step': stepsField},
          onlyInputClass
        ]"
        @focus="inputFocusStatus(true)"
        @blur="inputFocusStatus(false)"
        @keydown="handleEventClick($event)"
      >
      <div
        v-else
        class="text-field__link"
      >
        <a
          :href="model"
          target="_blank"
        >
          {{ model }}
        </a>
      </div>
      <div
        v-if="clearButton"
        class="text-field-wrapper__circle"
        @click="clearText()"
      >
        <i class="ub-icon-close-circle"></i>
      </div>
      <i
        v-if="customIcon"
        class="text-field__icon"
        :class="customIcon"
        @click="handleClickIcon($event)"
      >
      </i>
      <i
        v-if="icon && model.length && !errorStatus"
        class="text-field__icon"
        :class="icon"
        @click="handleClickIcon($event)"
      >
      </i>
      <i
        v-if="icon && typeInput === 'text' && model.length && !errorStatus"
        class="ub-icon-eye-hidden text-field__icon"
        @click="handleClickIcon($event)"
      ></i>
      <i
        v-if="errorStatus && !hideIconError"
        class="ub-icon-info-valid text-field__icon text-field__icon_error"
      ></i>
      <i
        v-if="calendar && !errorStatus"
        class="ub-icon-calendar text-field__icon"
        @click="handleClickIcon($event)"
      ></i>
      <i
        v-if="time && !errorStatus"
        class="ub-icon-clock text-field__icon"
        @click="handleClickIcon($event)"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    clearButton: {
      type: Boolean,
      default: false,
    },
    hideIconError: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => '',
    },
    onlyLabelClass: {
      type: String,
      default: () => '',
    },
    labelText: {
      type: String,
      default: () => '',
    },
    typeInput: {
      type: String,
      default: () => '',
    },
    onlyInputClass: {
      type: String,
      default: () => '',
    },
    customClass: {
      type: String,
      default: () => '',
    },
    placeholderText: {
      type: String,
      default: () => '',
    },
    autofocus: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [String, Number, Date],
    },
    icon: {
      type: String,
      default: '',
    },
    typeField: {
      type: String,
      default: '',
    },
    customIcon: {
      type: String,
      default: '',
    },
    errorStatus: {
      type: Boolean,
      default: false,
    },
    calendar: {
      type: Boolean,
      default: () => false,
    },
    time: {
      type: Boolean,
      default: () => false,
    },
    showModal: {
      type: Boolean,
      default: () => false,
    },
    textCapitalize: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    stepsField: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(data) {
        if (data.length && this.textCapitalize) {
          const str = data[0].toUpperCase() + data.slice(1);
          this.$emit('update:value', str);
        } else {
          this.$emit('update:value', data);
        }
      },
    },
  },
  watch: {
    typeField() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },
  mounted() {
    if (this.$refs !== undefined) {
      if (this.autofocus) {
        this.$refs.input.focus();
      }
    }
  },
  methods: {
    inputFocusStatus(e) {
      this.$emit('inputFocusStatus', e);
      if (this.showModal && e) {
        this.$emit('handleClickIcon');
      }
      if (!this.model && e) {
        this.model = '';
      }
    },
    clearText() {
      this.$emit('clearInputValue');
      this.model = '';
    },
    handleClickIcon(e) {
      this.$emit('handleClickIcon', e);
    },
    handleEventClick(e) {
      if (e.keyCode === 13) {
        this.$emit('handleEventClick');
      } else {
        this.$emit('changeInput');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.text-field {

  &_blue {

    .text-field__icon {
      color: $color-dodger-blue;
    }
  }

  &__link {
    height: 40px;
    padding: 12px 50px 12px 12px;
    width: 100%;
    border-left: 1px solid transparent;

    a {
      font: $font-size-h3 $font-global;
      color: $color-black;
      text-decoration: none;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

  }

  &_pointer {
    user-select: none;

    input {
      cursor: pointer;
      transition: background-color .15s ease-in;

      &:focus {
        border-color: $color-gallery;
      }

      &:hover {
        background: rgba($color-dodger-blue, .08);
      }
    }
  }
  &_alt {
    .text-field {
      &__label {
        margin-bottom: 2px;
      }
    }
  }

  &_chat {
    width: 100%;

    input {
      border: none;
      padding-right: 12px;
      font-size: $font-size-xlg;

      &::placeholder {
        color: $color-silver-chalice;
      }
    }
  }

  &_medium {
    input {
      font-family: $font-global-medium;
    }
  }

  &_364 {
    min-width: 364px;
    max-width: 364px;
  }

  &_200 {
    min-width: 200px;
    max-width: 200px;
  }

  &-wrapper {
    position: relative;

    &__circle {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 8px;
      right: 12px;
      cursor: pointer;
      transition: opacity .15s ease-in;

      &:hover {
        opacity: .8;
      }

      i {
        color: $color-dodger-blue;
        font-size: $font-size-icon-xd;
        background: rgba($color-dodger-blue, .08);
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }

    &_error {
      .text-field__input {
        border: 1px solid $color-cardinal!important;
      }
    }

    &_disabled {
      pointer-events: none;
    }
  }

  &__icon {
    position: absolute;
    right: 12px;
    top: 50%;
    color: $color-silver-chalice;
    transition: color .15s ease-in;
    cursor: pointer;
    transform: translateY(-50%);

    &_error {
      pointer-events: none;
      color: $color-cardinal!important;
    }

    &:hover {
      color: $color-dodger-blue;
    }
  }

  &__input {
    border: 1px solid $color-gallery;
    border-radius: $borderRadius;
    width: 100%;
    height: 40px;
    padding: 12px 50px 12px 12px;
    font: $font-size-base $font-global;
    line-height: 1.42 ;
    transition: border-color .15s ease-in;

    &_landing {
      font-family: $font-landing-global;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &_padding {
      padding-right: 35px;
    }

    &:focus {
      border-color: $color-dodger-blue;
    }

    &_step {
      &:focus::placeholder{
        opacity: 1;
      }
    }
  }

    &__label {
      color: $color-silver-chalice;
      font: $font-size-md $font-global-medium;
      line-height: 1.55;
      margin-bottom: 5px;

      &_landing {
        font-family: $font-landing-medium;
      }
    }

  &.active {
    i {
      color: $color-dodger-blue;
    }
  }
}

.ub-icon-check-mark-select {
  font-size: $font-size-h2;
}
</style>
