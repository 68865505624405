export const orderStatus = {
  NEW_ORDER: 'newOrder',
  ORDER_CONFIRMED_NOT_PAYED: 'orderConfirmedNotPayed',
  ORDER_CONFIRMED_AND_PAYED: 'orderConfirmedAndPayed',
  DOCUMENTS_REQUESTED: 'documentsRequested',
  DOCUMENTS_RECEIVED: 'documentsReceived',
  ALL_STEPS_COMPLETED: 'allStepsCompleted',
  PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED: 'projectWithFullyPayedOrderAccepted',
  LAST_PAYMENT_REQUIRED: 'lastPaymentRequired',
  LAST_PAYMENT_PAYED: 'lastPaymentPayed',
  LAST_PAYMENT_CONFIRMED: 'lastPaymentConfirmed',
  PROJECT_FINISHED: 'projectFinished',
  DOCUMENTS_SENT_AFTER_LAST_PAYMENT: 'documentsSentAfterLastPayment',
  PARTNER_REWARD_PERCENT_CHANGED: 'partnerRewardPercentChanged',
  ORDER_CANCELLED: 'orderCancelled',
  ON: 'ON',
  RD: 'RD',
  PD: 'PD',
  DD: 'DD',
  IP: 'IP',
  CNP: 'CNP',
  PPA: 'PPA',
  PR: 'PR',
  SD: 'SD',
  CTD: 'CTD',
  CD: 'CD',
};

export const ADMIN_BUTTON_COLOR = {
  [orderStatus.NEW_ORDER]: '#EEAC05',
  [orderStatus.ORDER_CONFIRMED_NOT_PAYED]: '#EEAC05',
  [orderStatus.ORDER_CONFIRMED_AND_PAYED]: '#276EF1',
  [orderStatus.DOCUMENTS_REQUESTED]: '#276EF1',
  [orderStatus.DOCUMENTS_RECEIVED]: '#276EF1',
  [orderStatus.LAST_PAYMENT_REQUIRED]: '#EEAC05',
  [orderStatus.LAST_PAYMENT_PAYED]: '#EEAC05',
  [orderStatus.ALL_STEPS_COMPLETED]: '#EEAC05',
  [orderStatus.LAST_PAYMENT_CONFIRMED]: '#09BC05',
  [orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT]: '#09BC05',
  [orderStatus.PROJECT_FINISHED]: '#09BC05',
};

export const CLIENT_BUTTON_COLOR = {
  [orderStatus.NEW_ORDER]: '#EEAC05',
  [orderStatus.ORDER_CONFIRMED_NOT_PAYED]: '#EEAC05',
  [orderStatus.ORDER_CONFIRMED_AND_PAYED]: '#EEAC05',
  [orderStatus.DOCUMENTS_REQUESTED]: '#276EF1',
  [orderStatus.DOCUMENTS_RECEIVED]: '#276EF1',
  [orderStatus.ALL_STEPS_COMPLETED]: '#276EF1',
  [orderStatus.LAST_PAYMENT_REQUIRED]: '#EEAC05',
  [orderStatus.LAST_PAYMENT_PAYED]: '#EEAC05',
  [orderStatus.LAST_PAYMENT_CONFIRMED]: '#09BC05',
  [orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT]: '#09BC05',
  [orderStatus.PROJECT_FINISHED]: '#09BC05',
};

export const PARTNER_BUTTON_COLOR = {
  [orderStatus.DOCUMENTS_REQUESTED]: '#276EF1',
  [orderStatus.DOCUMENTS_RECEIVED]: '#276EF1',
  [orderStatus.ORDER_CONFIRMED_AND_PAYED]: '#276EF1',
  [orderStatus.LAST_PAYMENT_REQUIRED]: '#EEAC05',
  [orderStatus.LAST_PAYMENT_PAYED]: '#EEAC05',
  [orderStatus.LAST_PAYMENT_CONFIRMED]: '#09BC05',
  [orderStatus.ALL_STEPS_COMPLETED]: '#EEAC05',
  [orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT]: '#09BC05',
  [orderStatus.PROJECT_FINISHED]: '#09BC05',
};

export const managerConfirmedNotPayedOrder = 'managerConfirmedNotPayedOrder';
export const managerConfirmedPayedOrder = 'managerConfirmedPayedOrder';
export const orderPayedAfterManagerConfirmed = 'orderPayedAfterManagerConfirmed';
export const orderPayedWithCashAfterManagerConfirmed = 'orderPayedWithCashAfterManagerConfirmed';
export const requestDocuments = 'requestDocuments';
export const receiveDocuments = 'receiveDocuments';
export const completeAllOrderSteps = 'completeAllOrderSteps';
export const acceptWorkWithFullyPayedOrder = 'acceptWorkWithFullyPayedOrder';
export const acceptedProjectRequireLastPay = 'acceptedProjectRequireLastPay';
export const clientPayedLastPayment = 'clientPayedLastPayment';
export const managerConfirmedLastPayment = 'managerConfirmedLastPayment';
export const managerFinishedProject = 'managerFinishedProject';
export const cancelOrder = 'cancelOrder';
export const sendPayedProjectDocuments = 'sendPayedProjectDocuments';
export const notAcceptPartnerWork = 'notAcceptPartnerWork';

export const statusColorList = {
  [orderStatus.ON]: 'orange',
  [orderStatus.RD]: 'main',
  [orderStatus.PD]: 'main',
  [orderStatus.DD]: 'main',
  [orderStatus.IP]: 'main',
  [orderStatus.CNP]: 'orange',
  [orderStatus.CTD]: 'green',
  [orderStatus.PR]: 'main',
  [orderStatus.PPA]: 'orange',
  [orderStatus.AT]: 'main',
  [orderStatus.SD]: 'main',
  [orderStatus.CD]: 'green',
  [orderStatus.NEW_ORDER]: 'orange',
  [orderStatus.ORDER_CONFIRMED_NOT_PAYED]: 'orange',
  [orderStatus.ORDER_CONFIRMED_AND_PAYED]: 'blue',
  [orderStatus.DOCUMENTS_RECEIVED]: 'blue',
  [orderStatus.DOCUMENTS_REQUESTED]: 'blue',
  [orderStatus.ALL_STEPS_COMPLETED]: 'blue',
  [orderStatus.PROJECT_WITH_FULLY_PAYED_ORDER_ACCEPTED]: 'green',
  [orderStatus.LAST_PAYMENT_REQUIRED]: 'orange',
  [orderStatus.LAST_PAYMENT_PAYED]: 'orange',
  [orderStatus.LAST_PAYMENT_CONFIRMED]: 'green',
  [orderStatus.PROJECT_FINISHED]: 'green',
  [orderStatus.DOCUMENTS_SENT_AFTER_LAST_PAYMENT]: 'green',
  [orderStatus.PARTNER_REWARD_PERCENT_CHANGED]: 'green',
  [orderStatus.ORDER_CANCELLED]: 'red',
};
