import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  requestCall(form) {
    const instWithCred = axios.create({
      baseURL: domains.FORM_API,
    });
    return instWithCred.post('request-call', form);
  },
  requestStepsForm(form) {
    const instWithCred = axios.create({
      baseURL: domains.FORM_API,
    });
    return instWithCred.post('steps-form', form);
  },
  requestOfferForm(form) {
    const instWithCred = axios.create({
      baseURL: domains.FORM_API,
    });
    return instWithCred.post('offer-form', form);
  },
  sendRequestForm(form) {
    const instWithCred = axios.create({
      baseURL: domains.FORM_API,
    });
    return instWithCred.post('reguest-consultation', form);
  },
};
