import * as types from '@/store/constant/mutationsTypes';

const state = {
  allTypeServicesList: [],
};

const getters = {
  allTypeServicesList: (state) => state.allTypeServicesList,
};

const actions = {
  setAllTypeServicesList({ commit }, data) {
    commit(types.SET_ALL_TYPE_SERVICES_LIST, data);
  },
};

const mutations = {
  [types.SET_ALL_TYPE_SERVICES_LIST](state, data) {
    state.allTypeServicesList = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
