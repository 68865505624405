const landingHeaderMenu = [
  {
    id: 1,
    text: 'headerPanel.aboutTheProject',
    route: 'about',
  },
  {
    id: 2,
    text: 'headerPanel.partners',
    route: 'partner',
  },
  {
    id: 3,
    text: 'headerPanel.contacts',
    route: 'contacts',
  },
];
export default landingHeaderMenu;
