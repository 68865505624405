<template>
  <div class="landing-page-modal">
    <div class="landing-page-modal-wrap">
      <div class="landing-page-modal-wrap-header">
        <div class="landing-page-modal-wrap-header__title">
          {{ $t('cookieModalInfo.title') }}
        </div>
        <div
          class="landing-page-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle landing-page-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="landing-page-modal-wrap-content">
        <div class="landing-page-modal-wrap-content__text">
          {{ $t('cookieModalInfo.textPart1') }}
        </div>
        <div class="landing-page-modal-wrap-content__text">
          {{ $t('cookieModalInfo.textPart2') }}
        </div>
      </div>
      <div class="landing-page-modal-wrap-footer">
        <div class="landing-page-modal-wrap-footer__item">
          <button
            class="landing-page__button landing-page__button_primary"
            @click="closeModal"
          >
            {{ $t('global.ok') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="landing-page-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'CookieModalInfo',
  methods: {
    closeModal() {
      this.$emit('modalCookieInfo', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";
@import "src/sass/mixins";

.landing-page-modal {

  &-wrap {
    width: 600px;

    @include mobile {
      width: 320px;
    }

    @include mobile-old {
      width: 310px;
    }

    &-header__title {
      @include mobile {
        font-size: $font-size-ml;
      }
    }

    &-content {

      &__text {
        line-height: 1.5;

        @include mobile-start {
          font-size: $font-size-landing-small;
        }

        @include mobile-old {
          font-size: $font-size-sm;
        }

        & + .landing-page-modal-wrap-content__text {
          margin-top: 20px;

          @include mobile {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

</style>
